import React, { useState } from 'react';
import { CircularProgress, styled, Table, TableBody, TableCell, TableRow } from '@mui/material';
import MuiTableHead from './MuiTableHead';
import { defaultComparator } from '../../lib/utils';

const MuiTable = ({
  defaultOrderBy,
  headCells,
  getComparator = defaultComparator,
  data,
  spinner,
  alignTop = false,
  children,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows = React.useMemo(
    () => data ? [...data].sort(getComparator(order, orderBy)) : null,
    [data, getComparator, order, orderBy],
  );

  const StyledTable = styled(Table)(({ theme }) => ({
    '.MuiTableRow-head .MuiTableCell-head': {
      backgroundColor: theme.palette['light-grey'].main,
    },
  }));

  return (
    <StyledTable
      aria-labelledby="events-table"
      size="medium"
      sx={{
        backgroundColor: 'black',
        color: 'blue',
        '& .MuiTableRow-head .MuiTableCell-head': {
          backgroundColor: 'black',
          color: 'blue',
        },
        ...(alignTop ? {
          '& .MuiTableCell-root': {
            verticalAlign: 'top',
          }
        } : {}),
      }}
    >
      <MuiTableHead
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        noWrap
      />
      <TableBody>
        {(spinner && !rows) && (
          <TableRow>
            <TableCell colSpan={headCells.length} sx={{ textAlign: 'center' }}>
              <CircularProgress size="2rem" />
            </TableCell>
          </TableRow>
        )}
        {(!spinner || rows !== null) && rows.map((row, index) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
          >
            { children(row) }
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default MuiTable;
