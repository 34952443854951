import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { API, graphqlOperation } from 'aws-amplify';
import { Alert, Button, Link, useTheme } from '@mui/material';
import { updateUser } from '../../../../graphql/mutations';
import { counties, states, userOptions } from '../../../../lib/constants';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../../lib/contexts/userContext';
import { AutocompleteElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Unstable_Grid2';
import RadioButtonGroup from '../../../common/HookFormMui/RadioButtonGroup';
import { PageContext } from '../../../../lib/contexts/pageContext';
import PhoneMask from '../../../common/PhoneMask';
import { simpTextAddContact, simpTextAddUnsub, simpTextRemoveUnsub } from '../../../../lib/api';
import { scrollToAlert, tenDigit } from '../../../../lib/utils';
import { useConfirm } from '../../../common/hooks/useConfirm';

const Profile = () => {
  const navigate = useNavigate();
  const {userState: {userRecord}, updateUserRecord} = useContext(UserContext);
  const {setPageMessage, setPageMessages, setPageErrorMessage} = useContext(PageContext);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();
  const confirm = useConfirm();

  useEffect(() => {
    if (!userRecord) {
      console.warn('No user record, redirecting to login');
      navigate('/login?redirect=/member/profile');
    }
  });

  const defaultValues = userRecord || {};
  Object.keys(defaultValues).forEach((key) => {
    if (defaultValues[key] === 'null') {
      defaultValues[key] = null;
    }
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      submitCount,
      isValidating,
    }
  } = useForm({
    defaultValues: {
      ...userRecord,
      is_eligible: userRecord.is_eligible !== null ? userRecord.is_eligible.toString() : null,
    },
    shouldFocusError: false,
  });

  const stateValue = watch('state', null);

  useEffect(() => {
    // When state is Illinois we switch to a dropdown list of counties.
    // If the county is not in that list, then clear it to force the user to update county.
    if (stateValue === 'IL') {
      const county = getValues('county');
      if (!counties.includes(county)) {
        setValue('county', '');
      }
    }
  }, [getValues, setValue, stateValue]);

  const hasErrors = errors && Object.keys(errors).length > 0;

  useEffect(() => {
    if (hasErrors) {
      document.getElementsByClassName('page-title')[0].scrollIntoView({ behavior: 'smooth' });
    }
  }, [hasErrors, submitCount]);

  useEffect(() => {
    setWasSubmitted(true);
  }, [submitCount]);

  useEffect(() => {
    if (isValidating && wasSubmitted) {
      setWasSubmitted(false);
    }
  }, [isValidating, wasSubmitted, setWasSubmitted]);

  useEffect(() => {
    if (isValidating) {
      setPageMessage(null);
    }
  }, [isValidating, setPageMessage]);

  const onSubmit = async (data) => {
    setPageMessage('');
    setPageMessages([]);
    setPageErrorMessage('');
    if (!data.phone_number.match(/[0-9]{10}/)) {
      return false;
    }
    const messages = [];
    setSubmitting(true);
    // Phone number must be formatted with country code, ie: '+15551234567'
    let phoneDigits = data.phone_number.replaceAll(/[^0-9]/g, '');
    if (phoneDigits && phoneDigits.length === 10) {
      phoneDigits = `+1${phoneDigits}`;
    }
    data.allow_cellphone = userRecord.allow_cellphone;
    data.allow_notification = userRecord.allow_notification;

    delete data.events_attended;
    delete data.activities;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.points;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.owner;
    delete data.__typename;
    delete data.select;
    data = {
      ...data,
      phone_number: phoneDigits,
      household_size: data.household_size || null
    };

    if (userRecord.allow_text_program && userRecord.phone_number !== data.phone_number) {
      // Ask for re-enrollment to text program
      const enroll = await confirm({
        title: '',
        description: 'You are currently enrolled to receive text messages for the Healthy Text Program. Do you want to enroll your updated phone number to receive Healthy Text Program messages as well?',
        confirmationButtonProps: {
          variant: 'contained',
        },
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
        .then(() => true)
        .catch(() => false);

      if (enroll) {
        try {
          // Add the old number to the unsubscribe list
          await simpTextAddUnsub({ phone: tenDigit(userRecord.phone_number) });
          // Remove the new number from the unsubscribe list (if it happened to be there)
          await simpTextRemoveUnsub({ phone: tenDigit(data.phone_number) });
          // Add contact to the list (subscribe)
          await simpTextAddContact({
            phone: tenDigit(data.phone_number),
            group: data.county,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
          });
          messages.push('You have been enrolled to receive Healthy Text Program messages.');
          setPageMessages([...messages]);
        } catch (err) {
          console.error('SimpText sub error', err);
          // Update the enrollment status to NOT enrolled since we weren't able to reliably subscribe the new number
          data.allow_text_program = false;
          setPageErrorMessage('We were unable to enroll your new phone number to receive Healthy Text Program messages due to technical difficulties. Please try again later.');
          scrollToAlert();
        }
      } else {
        try {
          // Add the old number to the unsubscribe list
          await simpTextAddUnsub({ phone: tenDigit(userRecord.phone_number) });
        } catch (err) {
          console.error('SimpText unsub error', err);
        } finally {
          // Update the enrollment status to NOT enrolled since the new number won't be subscribed even if the old number
          // wasn't successfully unsubscribed.
          data.allow_text_program = false;
        }
      }
    }

    try {
      data.id = userRecord.id;
      const userRecordResponse = await API.graphql({
        ...graphqlOperation(updateUser, {input: data}),
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (userRecordResponse.data.updateUser) {
        updateUserRecord(userRecordResponse.data.updateUser);
        messages.push('Profile Updated');
        setPageMessages([...messages]);
        scrollToAlert()
      }
      setSubmitting(false);
      return userRecordResponse;
    } catch (error) {
      setSubmitting(false);
      // console.log('Error: ', error);
      if (error.errors) {
        setPageErrorMessage(error.errors.map((e) => e.message).join('<br>'));
        scrollToAlert()
      } else {
        setPageErrorMessage(error.message);
        scrollToAlert()
      }
    }
  };

  useEffect(() => {
    // setting init values for custom handled fields
    if (userRecord && Object.keys(userRecord).length > 0) {
      Object.keys(userRecord).forEach((key) => {
        if (userRecord[key] === 'undefined' || userRecord[key] === 'null') {
          userRecord[key] = null;
        }
      });
      const {
        first_name,
        last_name,
        email,
        street_address,
        city,
        zip_code,
        county,
        phone_number,
        gender,
        // allow_cellphone,
        // allow_notification,
        is_eligible
      } = userRecord;
      let state = userRecord.state;

      setValue('first_name', first_name);
      setValue('last_name', last_name);
      setValue('email', email);
      setValue('street_address', street_address);
      setValue('city', city);
      setValue('zip_code', zip_code);
      setValue('county', county);
      setValue('gender', gender);

      setValue('phone_number', phone_number?.replace(/^\+1/, ''));

      // State must be abbreviated, ie: 'IL'
      if (state && state.length > 2) {
        state = states.find(({name}) => name === state).abbreviation;
      }
      setValue('state', state);

      setValue('is_eligible', is_eligible !== null ? is_eligible.toString() : null);
    }
  }, [userRecord, setValue]);

  const getErrors = () => {
    const messages = [];
    let hasRequiredError = false;
    Object.values(errors).forEach((err) => {
      if (err.type !== 'required') {
        messages.push(err.message);
      } else if (!hasRequiredError && wasSubmitted) {
        messages.push('Please fill out all required (*) fields.');
        hasRequiredError = true;
      }
    });
    return messages;
  };

  const errorMessages = getErrors();

  const getOptionLabel = (dropdown) => (value) => {
    const opt = userOptions[dropdown].find((opt) => opt.value === value);
    if (opt) {
      return `${opt.label || opt.value}`;
    }
    return null;
  };

  return (
    <div className={'form'} id={'profile-form'}>
      {!!errorMessages.length && errorMessages.map((err, i) =>
        <Alert severity="error" sx={{ marginBottom: '20px' }} key={`err${i}`}>
          {err}
        </Alert>
      )}
      <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{className: 'mui'}}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-first_name"
              name="first_name"
              label={'First Name'}
              variant={'standard'}
              control={control}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-last_name"
              name="last_name"
              label={'Last Name'}
              variant={'standard'}
              control={control}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-street_address"
              name="street_address"
              label={'Street Address'}
              variant={'standard'}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-city"
              name="city"
              label={'City/Town'}
              variant={'standard'}
              control={control}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <SelectElement
              id="field-state"
              name="state"
              label={'State'}
              variant={'standard'}
              control={control}
              required
              fullWidth
              options={states.map(item => ({id: item.abbreviation, label: item.name}))}
            />
          </Grid>
          <Grid xs={12} sm={2}>
            <TextFieldElement
              id="field-zip"
              name="zip_code"
              label={'Zip Code'}
              variant={'standard'}
              control={control}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={8}>
            {stateValue === 'IL' ? (
              <AutocompleteElement
                name="county"
                label="County"
                textFieldProps={{ variant: 'standard' }}
                control={control}
                required
                fullWidth
                options={counties}
                autocompleteProps={{
                  id: 'field-county',
                }}
              />
            ) : (
              <TextFieldElement
                id="field-county"
                name="county"
                label={'County'}
                variant={'standard'}
                control={control}
                required
                fullWidth
              />
            )}
          </Grid>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-email"
              name="email"
              type={'email'}
              label={'Email'}
              variant={'standard'}
              control={control}
              disabled={true}
              fullWidth
              helperText={
                <>
                  (To update your email address, please contact <Link href="mailto:eatmovesave@illinois.edu">eatmovesave@illinois.edu</Link>)
                </>
              }
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <TextFieldElement
              id="field-phone_number"
              name="phone_number"
              type={'text'}
              label={'Cell Phone Number'}
              variant={'standard'}
              control={control}
              validation={{
                validate: {
                  tenDigits: (value) => value.replaceAll(/[^0-9]+/g, '').length === 10 || 'Phone number must be 10 digits',
                },
              }}
              InputProps={{
                inputComponent: PhoneMask,
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={10} id="field-is_eligible">
            <RadioButtonGroup
              name={'is_eligible'}
              label={'Are you eligible to participate in any of the following programs?'}
              formLabelProps={{
                focused: false,
              }}
              row={true}
              description={(error) => (
                <ul style={{
                  listStyleType: 'none',
                  columns: 2,
                  paddingLeft: 0,
                  margin: 0,
                  color: error ? theme.palette.error.main : '#000'
                }}>
                  <li>SNAP/LINK</li>
                  <li>WIC</li>
                  <li>Free or reduced-price school lunch or breakfast</li>
                  <li>Free summer meals</li>
                  <li>Medicaid</li>
                  <li>Head start</li>
                  <li>Food pantries</li>
                  <li>Affordable housing or shelters</li>
                </ul>
              )}
              helperTextProps={{
                sx: {
                  marginRight: 0,
                  marginLeft: 0,
                  marginTop: 0,
                  color: '#000',
                },
              }}
              options={[
                {id: 'true', label: 'Yes'},
                {id: 'false', label: 'No'},
              ]}
              control={control}
              required
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <AutocompleteElement
              name="household_size"
              label={'Household Size'}
              textFieldProps={{variant: 'standard'}}
              control={control}
              fullWidth
              options={userOptions.householdSize.map(opt => opt.value)}
              autocompleteProps={{
                getOptionLabel: getOptionLabel('householdSize'),
                id: 'field-household_size',
              }}
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <AutocompleteElement
              name="gender"
              label={'Gender'}
              textFieldProps={{variant: 'standard'}}
              control={control}
              fullWidth
              options={userOptions.gender.map(opt => opt.value)}
              autocompleteProps={{
                getOptionLabel: getOptionLabel('gender'),
                id: 'field-gender',
              }}
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <AutocompleteElement
              name="ethnicity"
              label={'Ethnicity'}
              textFieldProps={{variant: 'standard'}}
              control={control}
              fullWidth
              options={userOptions.ethnicity.map(opt => opt.value)}
              autocompleteProps={{
                getOptionLabel: getOptionLabel('ethnicity'),
                id: 'field-ethnicity',
              }}
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <AutocompleteElement
              name="race"
              label={'Race'}
              textFieldProps={{variant: 'standard'}}
              control={control}
              fullWidth
              options={userOptions.race.map(opt => opt.value)}
              autocompleteProps={{
                getOptionLabel: getOptionLabel('race'),
                id: 'field-race',
              }}
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <AutocompleteElement
              name="age_range"
              label={'Age Range'}
              textFieldProps={{variant: 'standard'}}
              control={control}
              fullWidth
              options={userOptions.ageRange.map(opt => opt.value)}
              autocompleteProps={{
                getOptionLabel: getOptionLabel('ageRange'),
                id: 'field-age_range',
              }}
            />
          </Grid>
          <Grid xs={12} sm={8}>
            <Button
              id="button-submit"
              disabled={submitting}
              type={'submit'}
              variant="contained"
              size="small"
              sx={{ marginTop: '20px' }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </div>
  );
};

export default Profile;
