import { useState } from 'react';
import { Pages } from '../../../../../lib/constants';
import SessionDetails from './SessionDetails';
import Page from '../../../Page';

const SessionDetailsPage = () => {
  const [title, setTitle] = useState('');

  return (
    <Page
      pageId={Pages.SESSION_DETAILS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      title={title}
      requireAuth
    >
      <SessionDetails setTitle={setTitle} />
    </Page>
  )
};

export default SessionDetailsPage;
