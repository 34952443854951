import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';
import {Button, CircularProgress, IconButton, InputAdornment} from '@mui/material';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const ChangePasswordForm = ({user, code, setPageMessage, setPageErrorMessage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const [password, confirmPassword] = watch(['password', 'confirmPassword']);

  useEffect(() => {
    if (errors?.confirmPassword?.type === 'match') {
      if (password === confirmPassword) {
        clearErrors();
      }
    }
  }, [errors, password, confirmPassword, clearErrors]);

  const handlePassword = ({ email }) => {
    setSubmitting(true);
    setPageMessage('');
    setPageErrorMessage('');

    if (password !== confirmPassword) {
      setError('confirmPassword', { type: 'match', message: 'Passwords must match' });
      setSubmitting(false);
      return false;
    }

    Auth.forgotPasswordSubmit(user, code, password)
      .then(() => {
        setSubmitting(false);
        navigate('/login', { state: { message: 'Your password has been updated.' }});
      })
      .catch((err) => {
        let message = err.message;
        if (message === 'Invalid code provided, please request a code again.') {
          message = 'The verification link is invalid or expired. Please go to the Forgot Password page to request another verification link.';
        }
        setPageErrorMessage(message);
        setSubmitting(false);
      });
    return true;
  };

  return (
    <FormContainer onSuccess={handleSubmit(handlePassword)} FormProps={{ className: 'mui' }}>
      <Grid container spacing={3}>
        {submitting && (
          <Grid xs={12} display="flex" justifyContent="center">
            <CircularProgress/>
          </Grid>
        )}
        {!submitting && (
          <>
            <Grid xs={12}>
              <TextFieldElement
                id="field-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={'Password'}
                variant={'standard'}
                control={control}
                placeholder="Enter your password here"
                required
                fullWidth
                validation={{
                  minLength: {
                    value: 10,
                    message: 'Password must have at least 10 characters'
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid xs={12}>
              <TextFieldElement
                id="field-confirm_password"
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                label={'Confirm Password'}
                variant={'standard'}
                control={control}
                placeholder="Confirm your password here"
                required
                fullWidth
                validation={{
                  minLength: {
                    value: 10,
                    message: 'Password must have at least 10 characters'
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Button
                id="button-submit"
                variant="contained"
                size="small"
                type="submit"
                fullWidth
                sx={{ marginTop: '20px' }}
              >
                Submit
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </FormContainer>
  );
};

export default ChangePasswordForm;
