import { API, graphqlOperation } from 'aws-amplify';
import { listActivities } from '../../../graphql/queries';

const fetchUserActivities = async (user) => {
  try {
    const query = /* GraphQL */ `
      query ListUserActivities(
        $filter: ModelUserActivitiesFilterInput
        $limit: Int
      ) {
        listUserActivities(filter: $filter, limit: $limit) {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
          }
        }
      }
    `;
    const userActivities = await API.graphql(graphqlOperation(query, {
      filter: {
        userID: { eq: user.id }
      }
    }));

    return userActivities.data.listUserActivities.items.filter((userActivity) => !userActivity._deleted);
  } catch (error) {
    console.log('Error: ', error);
    return [];
  }
};

const fetchAllActivities = async () => {
  try {
    const activitiesData = await API.graphql(graphqlOperation(listActivities));

    const activitiesList = activitiesData.data.listActivities.items;

    // Order the activities in place by id (relative creation time) ascending.
    activitiesList.sort((a, b) => {
      return a.id - b.id;
    });

    return activitiesList;
  } catch (error) {
    console.log('Error: ', error);
    return [];
  }
};

export {
  fetchUserActivities,
  fetchAllActivities
};
