import React from 'react';
import { Typography } from '@mui/material';

const SessionsPast = () => {
  return (
    <div id="pagewrapper" className={'with-two-sidebar'} style={{ padding: 0 }}>
      <div id="content-wrapper" className={'event-details-card'}>
        <Typography variant="h4">
          Coming Soon!
        </Typography>
      </div>
    </div>
  );
};

export default SessionsPast;
