import React, {useCallback, useContext, useEffect, useState} from 'react';
import {fetchUserActivities, fetchAllActivities} from './activitiesFunctions';
import ActivityItem from './ActivityItem';
import UserContext from '../../../lib/contexts/userContext';
import {Box} from '@mui/system';
import {List} from '@mui/material';

const ActivitiesList = (props) => {
  const { userState: { userRecord } } = useContext(UserContext);
  const [activities, setActivities] = useState([]);
  const [userActivities, setUserActivities] = useState([]);

  const getUserActivities = useCallback(() => {
    if (userRecord?.id) {
      fetchUserActivities(userRecord)
        .then(userActivitiesList => {
          setUserActivities(userActivitiesList);
          return userActivitiesList;
        })
        .catch(() => {
          setUserActivities([]);
        });
    } else {
      setUserActivities([]);
    }
  }, [userRecord]);

  useEffect(() => {
    let isMounted = true;
    if (userRecord?.id) {
      fetchUserActivities(userRecord)
        .then(userActivitiesList => {
          if (isMounted) {
            setUserActivities(userActivitiesList);
          }
        })
        .catch(() => {
          if (isMounted) {
            setUserActivities([]);
          }
        });
    } else {
      if (isMounted) {
        setUserActivities([]);
      }
    }
    fetchAllActivities()
      .then(activitiesList => {
        if (isMounted) {
          setActivities(activitiesList);
        }
      })
      .catch(() => {
        if (isMounted) {
          setActivities([]);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [getUserActivities, userRecord]);

  const handleEnroll = () => {
    getUserActivities();
    props.onEnroll();
  };

  return (
    <Box>
      <List sx={{
        paddingTop: 0,
        'li:first-of-type': { paddingTop: 0 },
        'li': { marginBottom: '10px', paddingRight: '15px' }
      }}>
        {
          activities.map((activity) =>
            <ActivityItem
              key={activity.id}
              activity={activity}
              userActivities={userActivities}
              onEnroll={handleEnroll}
              setUserScore={props.setUserScore}
            />
          )
        }
      </List>
    </Box>
  );
};

export default ActivitiesList;
