import QRCode from 'qrcode';
import scrollIntoView from 'scroll-into-view';
import { Buffer } from 'buffer';

/**
 * Returns an array containing a single message(s), or null depending on the input.
 *
 * @param msg
 * @returns {{length}|*|*[]|null}
 */
export const getArrayMsg = (msg) => {
  if (Array.isArray(msg)) {
    if (msg.length) {
      return msg;
    } else {
      return null;
    }
  } else if (msg) {
    return [msg];
  } else {
    return null;
  }
};

/**
 * Creates a GraphQL filter using an ID filter combined with a provided permissions filter that was created from
 * casl permissions.
 *
 * @param itemId String
 * @param filterRules Object
 */
export const createFilter = (itemId, filterRules) => {
  const filterId = { id: { eq: itemId } };
  if (Object.keys(filterRules).length === 0) {
    return filterId;
  }
  const expand = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      let val = obj[key];
      if (Array.isArray(val)) {
        val = val.map((v) => expand(v));
      } else if (val && typeof val === 'object') {
        val = expand(val);
      }
      // event.created_by.whatever
      // => { event: { created_by: { whatever: val } } }
      // whatever: val, { created_by: .. },
      const keys = key.split('.');
      const keyVal = (v) => {
        if (keys.length > 1) {
          const k = keys.pop();
          return keyVal({ [k]: v });
        } else {
          return v;
        }
      }
      acc[keys[0]] = keyVal(val);
      return acc;
    }, {});
  };
  return expand({
    and: [
      filterId,
      filterRules,
    ]
  });
};

export const toOption = (id, label = null) => ({ id, label: label || id });

export const getFullName = (({ firstName, lastName }) => {
  let name = '';
  if (firstName || lastName) {
    name = [firstName || '', lastName || ''].join(' ');
  }
  return name;
});

/**
 * Format's a moment date/time into ISO standard as UTC without the conversion so that the
 * local time is pretended to be UTC all along.
 * @param m
 * @returns {string}
 */
export const notzUTCString = (m) => m.format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z';

export const decodeJWT = (token) => {
  const payload = token.split('.')[1];
  try {
    return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
  } catch (err) {
    return {};
  }
};

/**
 * Sort an array of items based on the display_order property.
 * If no display_order is set, then a fallback attribute is used for string comparison.
 * @param items
 * @param fallbackAttr
 * @returns {*}
 */
export const sortDisplayOrder = (items = [], fallbackAttr = null) => {
  items.sort((a, b) => {
    if (a.display_order && b.display_order) {
      return a.display_order - b.display_order;
    } else if (a.display_order) {
      return -1;
    } else if (b.display_order) {
      return 1;
    } else if (fallbackAttr) {
      return a[fallbackAttr].localeCompare(b[fallbackAttr]);
    }
    return a - b;
  });
  return items;
};

/**
 * Basic object comparator that compares the properties of objects 'a' and 'b' specified by 'orderBy'
 *
 * @param a
 * @param b
 * @param orderBy
 * @returns {number}
 */
export const descendingComparator = (a, b, orderBy) =>  {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Return comparator based on string direction.
 *
 * @param order
 * @param orderBy
 * @returns {{(*, *): number, (*, *): number}}
 */
export const defaultComparator = (order, orderBy) =>  {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const scrollToTitle = () => {
  setTimeout(() => {
    let elems = document.getElementsByClassName('page-title');
    if (elems.length > 0) {
      scrollIntoView(elems[0], { align: { top: 0 }});
    } else {
      window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
    }
  }, 100);
};

export const scrollToAlert = () => {
  setTimeout(() => {
    let e = document.getElementsByClassName('MuiAlert-root')[0];
    if (e) {
      scrollIntoView(e, { align: { top: 0 }});
    } else {
      scrollToTitle();
    }
  }, 100);
};

export const capitalize = (str) => `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;

export const last = (arr) => arr[arr.length - 1];

export const tenDigit = (phone) => {
  return phone.replace(/\+1/, '').replaceAll(/[^0-9]/g, '');
};

export const asYouTypePhone = (input, phoneNumber) => {
  const deleted = input.length < phoneNumber.length;
  if (input.length > 14) {
    return phoneNumber;
  }
  const chars = input.replaceAll(/[^() \-0-9]/g, '');
  let result = '';
  for (let i = 0; i < chars.length; i++) {
    const digit = chars[i];
    const lastDigit = result.length === chars.length - 1;
    const last2Digit = result.length === chars.length - 2;
    // console.log('i:', i, 'digit: ', digit, ' result len: ', result.length, 'last digit: ', lastDigit);
    const isNumeric = (val) => (val.length && !/[^0-9]/.test(val));
    const allowed = {
      0: /[(0-9]/g,
      4: /[)0-9]/g,
      5: /[ 0-9]/g,
      9: /[-0-9]/g,
    };
    if (i === 0 && digit === '1') {
      continue;
    }
    switch (result.length) {
      case 0:
        if (allowed[0].test(digit)) {
          if (isNumeric(digit)) {
            result += '(';
          }
          if (!deleted || !lastDigit) {
            result += digit;
          }
        }
        break;
      case 4:
        if (allowed[4].test(digit)) {
          if (isNumeric(digit)) {
            result += ') ';
          }
          if (!deleted || (!lastDigit && !last2Digit)) {
            result += digit;
          }
        }
        break;
      case 5:
        if (allowed[5].test(digit)) {
          if (isNumeric(digit)) {
            result += ' ';
          }
          if (!lastDigit) {
            result += digit;
          }
        }
        break;
      case 9:
        if (allowed[9].test(digit)) {
          if (isNumeric(digit)) {
            result += '-';
          }
          if (!deleted || !lastDigit) {
            result += digit;
          }
        }
        break;
      default:
        if (isNumeric(digit) && result.length < 14) {
          result += digit;
        }
        break;
    }
  }
  return result;
};

export const paletteMains = (theme) => Object.keys(theme.palette).reduce((acc, key) => {
  acc[key] = theme.palette[key].main;
  return acc;
}, {});

export const generateQRCode = async (text) => {
  try {
    return await QRCode.toDataURL(text);
  } catch (err) {
    console.error('QR Code generation error', err);
  }
};

export const generateShortCode = () => {
  const length = 8;
  const chars = '134679ACDEFHIJKLMNPQRTUVWXY';
  let shortCode = '';
  for (var i = length; i > 0; --i) {
    shortCode += chars[Math.floor(Math.random() * chars.length)];
  }
  return shortCode;
};
