import { Pages } from '../../../../lib/constants';
import Page from '../../Page';
import Activities from './Activities';

const ActivitiesPage = () => {
  return (
    <Page
      title="My Activities"
      sectionName="member"
      pageId={Pages.ACTIVITIES}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
    >
      <Activities />
    </Page>
  );
}

export default ActivitiesPage;
