/**
 * ROLES ENUM
 * Use .description to get the string version of the enum value.
 */
export const Roles = Object.freeze({
  ANON: Symbol('Anonymous'), // not logged in
  USER: Symbol('User'),
  STAFF: Symbol('myEMS Staff'),
  ADMIN: Symbol('myEMS Administrator'),
  SUPER_ADMIN: Symbol('myEMS Super Administrator'),
});

export const Pages = Object.freeze({
  EVENTS: 'EVENTS',
  EVENT_CREATE: 'EVENT_CREATE',
  EVENT_DETAILS: 'EVENT_DETAILS',
  EVENT_EDIT: 'EVENT_EDIT',
  SESSION_CREATE: 'SESSION_CREATE',
  SESSION_DETAILS: 'SESSION_DETAILS',
  SESSION_EDIT: 'SESSION_EDIT',
  UPCOMING_SESSIONS: 'UPCOMING_SESSIONS',
  PAST_SESSIONS: 'PAST_SESSIONS',
  PROFILE: 'PROFILE',
  ACTIVITIES: 'ACTIVITIES',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
});

export const NILL = '--';

/**
 * Returns the role associated with an enum value description.
 * @param str
 * @returns {string || null}
 */
export const roleFromString = (str) => {
  const roleKey = Object.keys(Roles).find((key) => Roles[key].description === str);
  if (roleKey) {
    return Roles[roleKey];
  }
  return null;
};

/**
 * Return the highest role associated with the users Cognito user groups.
 * @param groups Cognito user groups from id token
 * @returns {Symbol|null}
 */
export const roleFromGroups = (groups = []) => {
  const groupRoles = {
    SuperAdministrator: Roles.SUPER_ADMIN,
    Administrator: Roles.ADMIN,
    Staff: Roles.STAFF,
  };
  const roles = Object.keys(groupRoles).reduce((acc, groupName) => {
    if (groups.includes(groupName)) {
      acc.push(groupRoles[groupName]);
    }
    return acc;
  }, []);
  if (roles.length) {
    return roles[0];
  }
  return Roles.USER;
};

export const programAreas = [
  {
    id: 'snap_ed',
    label: 'SNAP-Ed - Extension'
  },
  {
    id: 'cphp',
    label: 'SNAP-Ed - CPHP'
  },
  {
    id: 'efnep',
    label: 'EFNEP'
  },
];

export const participantNeeds = [
  {
    id: 'food_access',
    label: 'Participant / Community food access',
  },
  {
    id: 'language',
    label: 'Language',
  },
  {
    id: 'traditions_behaviors',
    label: 'Cultural food traditions / behaviors',
  },
  {
    id: 'recipes',
    label: 'Cultural recipes',
  },
  {
    id: 'allergies',
    label: 'Food allergies',
  },
  {
    id: 'equipment_selection',
    label: 'Cooking equipment selection',
  },
  {
    id: 'reading_level',
    label: 'Participant reading level',
  },
  {
    id: 'mobility_adjustments',
    label: 'Activity adjustments for mobility',
  },
  {
    id: 'other',
    label: 'Other (Please describe)',
    text: true,
    textId: 'participant_needs_other',
  },
];

export const rewardLevels = [
  {
    'id': 1,
    'points': 50,
    'stars': 1,
    'item': 'Exercise band',
  },
  {
    'id': 2,
    'points': 200,
    'stars': 2,
    'item': 'Water bottle with diffuser',
  },
  {
    'id': 3,
    'points': 400,
    'stars': 3,
    'item': 'Insulated tote bag',
  },
  {
    'id': 4,
    'points': 600,
    'stars': 4,
    'item': 'Cooking utensil set',
  },
  {
    'id': 5,
    'points': 800,
    'stars': 5,
    'item': '2-quart slow cooker',
  }
];

export const userOptions = {
  householdSize: [
    {
      value: 1
    },
    {
      value: 2
    },
    {
      value: 3
    },
    {
      value: 4
    },
    {
      value: 5
    },
    {
      value: 6
    },
    {
      value: 7
    },
    {
      value: 8
    },
    {
      value: 9
    },
    {
      value: 10,
      label: '10 or more'
    }
  ],
  gender: [
    {
      value: 'Male'
    },
    {
      value: 'Female'
    },
    {
      value: 'Non-binary'
    },
    {
      value: 'Prefer not to answer'
    }
  ],
  ethnicity: [
    {
      value: 'Hispanic/Latino'
    },
    {
      value: 'Non-Hispanic/Non-Latino'
    },
    {
      value: 'Prefer not to answer'
    }
  ],
  race: [
    {
      value: 'American Indian/Alaska Native'
    },
    {
      value: 'Asian'
    },
    {
      value: 'Black or African American'
    },
    {
      value: 'Native Hawaiian or Other Pacific Islander'
    },
    {
      value: 'White'
    },
    {
      value: 'Prefer not to answer'
    }
  ],
  ageRange: [
    {
      value: '18-59 years'
    },
    {
      value: '60-75 years'
    },
    {
      value: '76+ years'
    },
    {
      value: 'Prefer not to answer'
    }
  ]
};

export const states = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];

export const counties = [
  'Adams',
  'Alexander',
  'Bond',
  'Boone',
  'Brown',
  'Bureau',
  'Calhoun',
  'Carroll',
  'Cass',
  'Champaign',
  'Christian',
  'Clark',
  'Clay',
  'Clinton',
  'Coles',
  'Cook',
  'Crawford',
  'Cumberland',
  'DeKalb',
  'DeWitt',
  'Douglas',
  'DuPage',
  'Edgar',
  'Edwards',
  'Effingham',
  'Fayette',
  'Ford',
  'Franklin',
  'Fulton',
  'Gallatin',
  'Greene',
  'Grundy',
  'Hamilton',
  'Hancock',
  'Hardin',
  'Henderson',
  'Henry',
  'Iroquois',
  'Jackson',
  'Jasper',
  'Jefferson',
  'Jersey',
  'Jo Daviess',
  'Johnson',
  'Kane',
  'Kankakee',
  'Kendall',
  'Knox',
  'Lake County',
  'La Salle',
  'Lawrence',
  'Lee',
  'Livingston',
  'Logan',
  'Macon',
  'Macoupin',
  'Madison',
  'Marion',
  'Marshall',
  'Mason',
  'Massac',
  'McDonough',
  'McHenry',
  'McLean',
  'Menard',
  'Mercer',
  'Monroe',
  'Montgomery',
  'Morgan',
  'Moultrie',
  'Ogle',
  'Peoria',
  'Perry',
  'Piatt',
  'Pike',
  'Pope',
  'Pulaski',
  'Putnam',
  'Randolph',
  'Richland',
  'Rock Island',
  'Saline',
  'Sangamon',
  'Schuyler',
  'Scott',
  'Shelby',
  'St. Clair',
  'Stark',
  'Stephenson',
  'Tazewell',
  'Union',
  'Vermillion',
  'Wabash',
  'Warren',
  'Washington',
  'Wayne',
  'White',
  'Whiteside',
  'Will',
  'Williamson',
  'Winnebago',
  'Woodford',
  ...(process.env.REACT_APP_ENV !== 'production' ? ['Test', 'EATMOVESAVE.'] : []),
];
