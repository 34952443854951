import React, { useContext } from 'react';
import { Alert, Link } from '@mui/material';
import { PageContext } from '../../lib/contexts/pageContext';

const Alerts = ({ modalContext = null }) => {
  let { pageMessages, pageErrorMessages } = useContext(PageContext);
  if (modalContext) {
    ({ pageMessages, pageErrorMessages } = modalContext);
  }
  const replaceSupportEmail = (str) => {
    const parts = str.split('<support email address>');
    return (
      <>
        {parts.map((s, i) => (
          <span key={`msg-${i}`}>
            <span>{s}</span>
            {(i < parts.length - 1) && (
              <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
            )}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      {pageMessages && pageMessages.length > 0 && pageMessages.map((message, i) => (
        <Alert severity="success" sx={{ marginBottom: '10px' }} key={`message-${i}`}>{replaceSupportEmail(message)}</Alert>
      ))}
      {pageErrorMessages && pageErrorMessages.length > 0 && pageErrorMessages.map((message, i) => (
        <Alert severity="error" sx={{ marginBottom: '10px' }} key={`message-${i}`}>{replaceSupportEmail(message)}</Alert>
      ))}
    </>
  )
};

export default Alerts;
