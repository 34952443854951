import React, { useState}  from 'react';
import Page from '../Page';
import {Box, Stack} from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  Paper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import {Link} from 'react-router-dom';
import signUpPhoto from '../../../sass/images/homepage/boxes/signup.jpg';
import signUpIcon from '../../../sass/images/homepage/SIGNUPicon.svg';
import logInPhoto from '../../../sass/images/homepage/boxes/login.jpg';
import logInIcon from '../../../sass/images/homepage/LOGINicon.svg';
import checkInPhoto from '../../../sass/images/homepage/boxes/checkin.jpg';
import checkInIcon from '../../../sass/images/homepage/CHECKINicon.svg';
import {paletteMains} from '../../../lib/utils';

const OfflineMessage = () => (
  <div>Thank you for visiting the myEat.Move.Save. Rewards page. This webpage and user accounts have been temporarily disabled following the end of our pilot on November 15th, 2024. We are working hard on system improvements based on feedback from our pilot users. If you would like to be notified when myEat.Move.Save. Rewards restarts email our team at <a href="mailto:eatmovesave@illinois.edu">eatmovesave@illinois.edu</a> and we will add you to our list!</div>
);

const HomePage = () => {
  const theme = useTheme();
  const palette = paletteMains(theme);
  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const BannerLogoBox = styled(Box)({
    width: '50%',
    [theme.breakpoints.down('sm')]: { width: '90%' },
    margin: '0 auto',
    position: 'relative',
    // top: '-3rem',
    zIndex: 0,
  });

  const Card = ({ to = '/', photo, icon, text }) => (
    <Grid xs={12} sm={4}>
      <Link to={to} onClick={(ev) => {
        ev.preventDefault();
        handleDialogOpen();
      }}>
        <Paper elevation={16} sx={{
          position: 'relative',
          background: `url(${photo})`,
          backgroundSize: 'cover',
          paddingTop: '118%',
        }}>
          <Stack sx={{
            padding: '24px',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Box sx={{
              backgroundImage: `url(${icon})`,
              backgroundSize: '40%',
              backgroundPosition: (to === '/signup' ? 'calc(50% + 12px) 50%' : '50%'),
              backgroundRepeat: 'no-repeat',
              width: '100%',
              paddingTop: '50%',
            }}/>
            <Typography variant="h3" fontWeight={600} color={'#fff'} align={'center'}>
              {text}
            </Typography>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
  return (
    <Page title="Home" pageName="home" contentGrid={false}>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <OfflineMessage />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ position: 'relative', paddingTop: '1rem' }}>
        <BannerLogoBox className="homepage-banner-logo">
          <img src={'/images/homepage/site_header-v2.png'} alt="site logo" width="100%" />
        </BannerLogoBox>
        <Grid container columns={24} sx={{ zIndex: 1 }}>
          <Grid xs={4} sm={1} md={1} lg={4}></Grid>
          <Grid xs={(24-(4*2))} sm={22} lg={16}>
            <Box>
              <Grid spacing={{ xs: 2, sm: 4 }} container>
                <Card
                  to={'/signup'}
                  photo={signUpPhoto}
                  icon={signUpIcon}
                  text={<>SIGN<br/>UP</>}
                />
                <Card
                  to={'/login'}
                  photo={logInPhoto}
                  icon={logInIcon}
                  text={<>LOG<br/>IN</>}
                />
                <Card
                  to={'/checkin'}
                  photo={checkInPhoto}
                  icon={checkInIcon}
                  text={<>CHECK<br />IN</>}
                />
              </Grid>
            </Box>

            <Box sx={{ align: 'center', marginTop: '64px' }}>
              <Alert severity="info">
                <OfflineMessage />
              </Alert>

              <Box align="center" mb={'16px'}>
                <Typography variant="h4" sx={{
                  display: 'inline',
                  color: palette.blue,
                  fontWeight: 600,
                  justifyContent: 'center',
                  marginBottom: '32px',
                }}>
                  What is
                  <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <img src={'/images/homepage/myEAT.MOVE.SAVE.blue.svg'} alt={'my eat.move.save content header'}
                         style={{
                           marginLeft: '10px',
                           top: `calc(((${theme.typography.h4.lineHeight} - 1) * ${theme.typography.h4.fontSize} * 2) + 3px)`,
                           position: 'relative',
                           maxWidth: 'calc(100% - 24px)',
                           width: 'auto',
                           height: `calc(${theme.typography.h4.fontSize} * ${theme.typography.h4.lineHeight})`,
                         }} />
                    ?
                  </span>
                </Typography>
              </Box>
              <Typography variant="body1" align={'center'}>
                myEat.Move.Save is a rewards program for participants of University of Illinois SNAP-Ed and EFNEP
                programs. Create an account, check in to events, earn points, and be eligible to receive rewards that
                support the health of you and your family!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default HomePage;
