import { AbilityBuilder, defineAbility } from '@casl/ability';
import { Roles } from './constants';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { isSessionSSO } from './contexts/userContext';
import { rulesToQuery } from '@casl/ability/extra';

export const defaultAbility = defineAbility(() => null);
export const AbilityContext = createContext(defaultAbility);
export const Can = createContextualCan(AbilityContext.Consumer);

export const Subjects = {
  PAGE_EVENTS: 'PAGE_EVENTS',
  PAGE_EVENT_CREATE: 'PAGE_EVENT_CREATE',
  PAGE_EVENT_EDIT: 'PAGE_EVENT_EDIT',
  PAGE_UPCOMING_SESSIONS: 'PAGE_UPCOMING_SESSIONS',
  PAGE_PAST_SESSIONS: 'PAGE_PAST_SESSIONS',
  PAGE_SESSION_CREATE: 'PAGE_SESSION_CREATE',
  PAGE_SESSION_DETAILS: 'PAGE_SESSION_DETAILS',
  PAGE_SESSION_EDIT: 'PAGE_SESSION_EDIT',
  PAGE_EVENT_DETAILS: 'PAGE_EVENT_DETAILS',
  PAGE_ACTIVITIES: 'PAGE_ACTIVITIES',
  PAGE_PROFILE: 'PAGE_PROFILE',
  PAGE_CHANGE_PASSWORD: 'PAGE_CHANGE_PASSWORD',
  EVENT: 'EVENT',
  EVENT_DETAILS: 'EVENT_DETAILS',
  SESSION: 'SESSION',
  SESSION_DETAILS: 'SESSION_DETAILS',
  PARTICIPANT: 'PARTICIPANT',
  DEMOGRAPHICS: 'DEMOGRAPHICS',
  YOUTH: 'YOUTH',
  ADULT: 'ADULT',
  SESSION_COLLABORATOR: 'SESSION_COLLABORATOR',
  EVENT_FLYER: 'EVENT_FLYER',
  EVENT_COLLABORATOR: 'EVENT_COLLABORATOR',
  EVENTS: 'EVENTS',
  OBSERVATION: 'OBSERVATION',
};

export const caslRuleInverter = (rule) => rule.inverted ? { $not: rule.conditions } : rule.conditions;

/**
 * Convert a rule to a GraphQL list filter.
 *
 * @param ability - the global ability for the logged-in user
 * @param action - the rule action
 * @param subject - the rule subject
 * @returns {AbilityQuery<{$not}|*>|any}
 */
export const toGraphQuery = (ability, action, subject) => {
  const query = rulesToQuery(ability, action, subject, caslRuleInverter);
  // Reduce rule to object with symbols interpretable by GraphQL (instead of sequelize)
  const symbolize = () => {
    return JSON.parse(JSON.stringify(query), function keyToSymbol(key, value) {
      if (key[0] === '$') {
        let symbol  = key.slice(1).toLowerCase();
        if (symbol === 'all') {
          symbol = 'or';
        }
        this[`${symbol}`] = value;
        return;
      } else if ( typeof value === 'string') {
        return { eq: value };
      }

      return value;
    });
  }
  return query === null ? query : symbolize(query);
}

export const definePermissions = (ability, userState) => {
  const { can, rules } = new AbilityBuilder();
  const role = userState?.userRole;
  // const userId = userState?.userRecord?.id;
  const userEmail = userState?.userRecord?.email;
  const hasAdminRole = [Roles.SUPER_ADMIN, Roles.ADMIN].includes(role);
  const hasStaffRole = role === Roles.STAFF;
  const hasUserRole = role === Roles.USER;

  const staffSessionPerms = (action, subject) => {
    // Can act on sessions for events they created
    can(action, subject, { 'event.created_by': userEmail });
    // Can act on events they are collaborators for
    // can(action, subject, { collaborators: { $all: [userId] }});
    // Can act on sessions of events they are collaborators for
    // can(action, subject, { 'event.collaborators': { $all: [userEmail] }});
  };

  if (hasAdminRole || hasStaffRole) {
    can('view', Subjects.PAGE_EVENTS);
  }

  if (hasAdminRole) {
    can('view', Subjects.EVENTS);
  } else if (hasStaffRole) {
    can('view', Subjects.EVENTS, { created_by: userEmail });
    // can('view', Subjects.EVENTS, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_EVENT_CREATE);
    can('create', Subjects.EVENT);
    can('assign', Subjects.EVENT);
  } else if (hasStaffRole) {
    can('view', Subjects.PAGE_EVENT_CREATE);
    can('create', Subjects.EVENT);
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_UPCOMING_SESSIONS);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PAGE_UPCOMING_SESSIONS);
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_PAST_SESSIONS);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PAGE_PAST_SESSIONS);
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_EVENT_DETAILS);
    can('view', Subjects.EVENT_DETAILS);
  } else if (hasStaffRole) {
    can('view', Subjects.PAGE_EVENT_DETAILS);
    can('view', Subjects.EVENT_DETAILS, { created_by: userEmail });
    // can('view', Subjects.EVENT_DETAILS, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_EVENT_CREATE);
    can('view', Subjects.PAGE_EVENT_EDIT);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PAGE_EVENT_CREATE);
    staffSessionPerms('view', Subjects.PAGE_EVENT_EDIT);
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_SESSION_CREATE);
    can('view', Subjects.PAGE_SESSION_EDIT);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PAGE_SESSION_CREATE);
    staffSessionPerms('view', Subjects.PAGE_SESSION_EDIT);
  }
  if (hasAdminRole) {
    can('edit', Subjects.EVENT);
  } else if (hasStaffRole) {
    can('edit', Subjects.EVENT, { created_by: userEmail });
    // can('edit', Subjects.EVENT, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('delete', Subjects.EVENT);
  } else if (hasStaffRole) {
    can('delete', Subjects.EVENT, { created_by: userEmail });
    // can('delete', Subjects.EVENT, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('complete', Subjects.EVENT);
  } else if (hasStaffRole) {
    can('complete', Subjects.EVENT, { created_by: userEmail });
    // can('complete', Subjects.EVENT, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('create', Subjects.SESSION);
  } else if (hasStaffRole) {
    can('create', Subjects.SESSION, { 'event.created_by': userEmail });
    // can('create', Subjects.SESSION, { 'event.collaborators': { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('add', Subjects.EVENT_COLLABORATOR);
  } else if (hasStaffRole) {
    can('add', Subjects.EVENT_COLLABORATOR, { created_by: userEmail });
    // can('add', Subjects.EVENT_COLLABORATOR, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('remove', Subjects.EVENT_COLLABORATOR);
  } else if (hasStaffRole) {
    can('remove', Subjects.EVENT_COLLABORATOR, { created_by: userEmail });
    // can('remove', Subjects.EVENT_COLLABORATOR, { collaborators: { $all: [userId] }});
  }
  if (hasAdminRole) {
    can('view', Subjects.PAGE_SESSION_DETAILS);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PAGE_SESSION_DETAILS);
  }
  if (hasAdminRole) {
    can('view', Subjects.EVENT_FLYER);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.EVENT_FLYER);
  }
  if (hasAdminRole) {
    can('view', Subjects.SESSION);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.SESSION);
  }
  if (hasAdminRole) {
    can('edit', Subjects.SESSION);
  } else if (hasStaffRole) {
    staffSessionPerms('edit', Subjects.SESSION);
  }
  if (hasAdminRole) {
    can('cancel', Subjects.SESSION);
  } else if (hasStaffRole) {
    staffSessionPerms('cancel', Subjects.SESSION);
  }
  if (hasAdminRole) {
    can('complete', Subjects.SESSION);
  } else if (hasStaffRole) {
    staffSessionPerms('complete', Subjects.SESSION);
  }
  if (hasAdminRole) {
    can('add', Subjects.SESSION_COLLABORATOR);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.SESSION_COLLABORATOR);
  }
  if (hasAdminRole) {
    can('remove', Subjects.SESSION_COLLABORATOR);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.SESSION_COLLABORATOR);
  }
  if (hasAdminRole) {
    can('add', Subjects.ADULT);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.ADULT);
  }
  if (hasAdminRole) {
    can('add', Subjects.YOUTH);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.YOUTH);
  }
  if (hasAdminRole) {
    can('collect', Subjects.DEMOGRAPHICS);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.DEMOGRAPHICS);
  }
  if (hasAdminRole) {
    can('remove', Subjects.PARTICIPANT);
  } else if (hasStaffRole) {
    staffSessionPerms('view', Subjects.PARTICIPANT);
  }
  if (hasAdminRole) {
    can('add', Subjects.OBSERVATION);
  } else if (hasStaffRole) {
    // cannot
  }

  if (hasUserRole || !isSessionSSO(userState?.userSession)) {
    can('view', Subjects.PAGE_CHANGE_PASSWORD);
    can('view', Subjects.PAGE_ACTIVITIES);
    can('view', Subjects.PAGE_PROFILE);
  }

  ability.update(rules);
};
