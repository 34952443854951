import React, { useContext } from 'react';
import { Breadcrumbs, Link, useTheme } from '@mui/material';
import { PageContext } from '../../lib/contexts/pageContext';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbTrail = () => {
  const { crumbs } = useContext(PageContext);

  const theme = useTheme();

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" marginBottom="16px">
      {crumbs.map((crumb, i) => (
        <Link
          key={`crumb-${i}`}
          underline={crumb.last ? 'hover': 'always' }
          color={ crumb.last ? 'primary' : 'secondary' }
          to={crumb.url}
          sx={crumb.last ? {} : {
            color: theme.palette.ilstorm.main,
            textDecorationColor: theme.palette.ilstorm.main
          }}
          component={RouterLink}
        >
          { crumb.label }
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;
