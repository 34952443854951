import React, {useContext, useEffect, useState} from 'react';
import {PageContext} from '../../../lib/contexts/pageContext';
import Page from '../Page';
import {useForm} from 'react-hook-form';
import {Auth} from 'aws-amplify';
import {Alert, Button, IconButton, InputAdornment, Typography} from '@mui/material';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui';
import Grid from '@mui/material/Unstable_Grid2';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Pages } from '../../../lib/constants';

const ChangePassword = () => {
  const { setPageMessage } = useContext(PageContext);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: {
      submitCount,
      isValidating,
      errors,
    },
  } = useForm({
    mode: 'onSubmit',
  });

  const errorValues = Object.values(errors);

  useEffect(() => {
    setWasSubmitted(true);
  }, [submitCount]);

  useEffect(() => {
    if (isValidating && wasSubmitted) {
      setWasSubmitted(false);
    }
  }, [isValidating, wasSubmitted, setWasSubmitted]);

  useEffect(() => {
    if (isValidating) {
      setPageMessage(null);
    }
  }, [isValidating, setPageMessage]);

  const onSubmit = async () => {
    setSubmitting(true);
    clearErrors('oldPassword');
    const [oldPassword, newPassword] = getValues(['oldPassword', 'newPassword']);
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        reset();
        setPageMessage('Password updated.');
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        if (err.code === 'NotAuthorizedException') {
          setError('oldPassword', {
            type: 'invalid',
            message: 'Your Current Password is incorrect; it\'s required to change your Password.'
          });
        } else {
          setError('oldPassword', { type: 'other', message: err.message });
        }
        return err.message;
      });
  };

  const getErrors = () => {
    if (!wasSubmitted) return [];
    const hasTypes = [];
    const errors = [];
    errorValues.forEach((err) => {
      if (!hasTypes.includes(err.type)) {
        hasTypes.push(err.type);
        let message = err.message;
        if (err.type === 'required') {
          message = 'Please fill out all required (*) fields.';
        }
        errors.push(message);
      }
    });
    return errors;
  };

  const errorMessages = getErrors();

  return (
    <Page
      title="Change Password"
      pageId={Pages.CHANGE_PASSWORD}
      navPhoto={{ image: '/images/profile/left-sidebar.png' }}
    >
      <Grid container gap={3} wrap={'nowrap'} columns={10} className="form" id="password-form">
        <Grid xs={10}>
          {!!errorMessages.length && errorMessages.map((err, i) => (
            <Alert severity="error" key={`err-${i}`}>
              {err.toString()}
            </Alert>
          ))}
          <FormContainer FormProps={{ className: 'mui' }} onSuccess={(form) => {
            setPageMessage(null);
            return handleSubmit(onSubmit)(form);
          }}>
            <Typography mb={'15px'} mt={'15px'} sx={{ fontWeight: 600 }} component="div">
              <label className={'form-text required'}>Required Fields</label>
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={12} sm={8}>
                <TextFieldElement
                  id="field-oldPassword"
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  label={'Current Password'}
                  variant={'standard'}
                  control={control}
                  placeholder="Enter your old password here"
                  autoComplete="current-password"
                  required
                  fullWidth
                  validation={{
                    minLength: {
                      value: 10,
                      message: 'Password must have at least 10 characters'
                    }
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </Grid>
              <Grid xs={12} sm={8}>
                <TextFieldElement
                  id="field-newPassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  label={'New Password'}
                  variant={'standard'}
                  control={control}
                  placeholder="Enter your new password here"
                  helperText="Minimum 10 characters. You can use letters, numbers, and symbols."
                  autoComplete="new-password"
                  required
                  fullWidth
                  validation={{
                    minLength: {
                      value: 10,
                      message: 'Password must have at least 10 characters'
                    }
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </Grid>
              <Grid xs={12} sm={8}>
                <Button
                  id="button-submit"
                  disabled={submitting}
                  type={'submit'}
                  variant="contained"
                  size="small"
                  sx={{ marginTop: '10px' }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>
    </Page>
  );
};


export default ChangePassword;
