import { API, graphqlOperation } from 'aws-amplify';
import { generateShortCode } from '../../../lib/utils';
import { queryDataMart } from '../../../lib/api';
import moment from '../../../lib/moment';

const runQuery = async (query, variables) => {
  try {
    return API.graphql({ ...graphqlOperation(query, variables), authMode: 'API_KEY' });
  } catch (error) {
    return {
      errors: [
        error
      ]
    };
  }
};

export const hydrateEventSessions = (event) => {
  const nonCanceledSessions = event.sessions?.items.filter(s => s.status !== 'CANCELED');

  if (!nonCanceledSessions.length) {
    event.dates = null;
    event.counts = null;
    return event;
  }
  const dates = { start: null, end: null };
  const counts = {
    completed: 0,
    total: 0,
  };
  nonCanceledSessions.forEach((session) => {
    const sessionStart = moment(session.start);
    const sessionEnd = moment(session.end);

    if (!dates.start) {
      dates.start = sessionStart;
    } else if (sessionStart.isBefore(dates.start)) {
      dates.start = sessionStart;
    }
    if (!dates.end) {
      dates.end = sessionEnd;
    } else if (sessionEnd.isAfter(dates.end)) {
      dates.end = sessionEnd;
    }

    counts.total += 1;
    if (session.status === 'COMPLETED') {
      counts.completed += 1;
    }

  });
  event.counts = counts;
  event.dates = dates;
  return event;
};

export const orderSessions = (sessions) => {
  sessions.sort((a, b) => {
    const aStart = moment(a.start);
    const bStart = moment(b.start);
    return aStart - bStart;
  });
  return sessions;
};

export const cancelSession = /* GraphQL */ `
  mutation CancelSession(
    $input: UpdateSessionInput!
  ) {
    updateSession(input: $input) {
      id
      status
      eventID
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          curricula {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            status
            eventID
            lessonID
            lesson {
              id
              curriculumID
              curriculum {
                id
                curriculum_name
                createdAt
                updatedAt
                owner
                __typename
              }
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        eventID
        event {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            lessons {
              items {
                id
                curriculumID
                lesson_name
                booth_topic
                snap_ed
                efnep
                cphp
                points
                description
                display_order
                audience
                createdAt
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              created_by
              updated_by
              createdAt
              updatedAt
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        lessonID
        lesson {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        start
        end
        delivery_method
        delivery_url
        delivery_language
        material_language
        delivery_location
        other_details
        has_wifi
        demonstration
        recipes
        short_code
        qr_code
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEventsWithLessons = /* GraphQL */ `
  query listEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          curricula {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start,
            end,
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            qr_code
            short_code
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;

export const getEventLessons = async (filter) => {
  const response = await runQuery(listEventsWithLessons, filter);
  let events = response.data.listEvents.items;
  if (!events.length) {
    return null;
  }
  const [event] = events;
  return event;
};

export const getLessons = async () => {
  const query = /* GraphQL */`
    query getAllCurriculum($filter: ModelCurriculumLessonFilterInput, $limit: Int = 1000) {
      listCurriculumLessons(filter: $filter, limit: $limit) {
        items {
          lesson_name
          booth_topic
          description
          snap_ed
          efnep
          cphp
          points
          display_order
          curriculum {
            curriculum_name
            additional {
              items {
                curriculumAdditional {
                  additional_name
                }
              }
            }
          }
        }
      }
    }
  `;

  const response = await runQuery(query);
  let curriculums = response.data.listCurriculumLessons.items;
  curriculums = curriculums.map((cur) => ({
    ...cur,
    curriculum_name: cur?.curriculum?.curriculum_name,
    additional: cur?.curriculum?.additional?.items?.map((a) => a.curriculumAdditional.additional_name),
  }));
  curriculums.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return curriculums;
};

export const getBoothTopics = async () => {
  const query = /* GraphQL */`
    query getBoothTopics($filter: ModelCurriculumLessonFilterInput, $limit: Int = 1000) {
      listCurriculumLessons(filter: $filter, limit: $limit) {
        items {
          id
          curriculumID
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
      }
    }
  `;

  let variables = {
    filter: {
      and: [
        { booth_topic: { attributeExists: true } },
        { booth_topic: { gt: '' } },
      ]
    }
  };

  const response = await runQuery(query, variables);
  const boothTopics = response.data.listCurriculumLessons.items;
  boothTopics.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return boothTopics;
};

export const getCurriculumLessons = async (curriculumID) => {
  const query = /* GraphQL */`
    query getCurriculumLessons($id: ID!) {
      getCurriculum(id: $id) {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        lessons {
          items {
            id
            curriculumID
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
    }
  `;

  let variables = {
    input: { id: curriculumID },
  };

  const response = await runQuery(query, variables);
  const lessons = response.data.getCurriculumLessons.items;
  lessons.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return lessons;
};

export const getCurricula = async () => {
  const query = /* GraphQL */`
    query getCurricula($filter: ModelCurriculumFilterInput, $limit: Int = 1000) {
      listCurricula(filter: $filter, limit: $limit) {
        items {
          id
          curriculum_name
          additional {
            items {
              curriculumAdditional {
                id
                additional_name
              }
            }
          }
          lessons {
            items {
              lesson_name
              snap_ed
              efnep
              cphp
            }
          }
        }
      }
    }
  `;

  const response = await runQuery(query);
  let curriculums = response.data.listCurricula.items;
  curriculums = curriculums.map((cur) => ({
    ...cur,
    additional: cur?.additional?.items?.map((a) => a.curriculumAdditional),
    program_areas: cur?.lessons?.items?.reduce((acc, lesson) => {
      ['snap_ed', 'efnep', 'cphp'].forEach((key) => {
        if (lesson[key] && !acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []),
  }));
  curriculums.sort((a, b) => a.curriculum_name.localeCompare(b.curriculum_name));
  return curriculums;
};

/**
 * Retrieve dropdown options from PEARs for create event form fields.
 * Currently, this is a placeholder until we get credentials for the PEARs database.
 */
export const getPearsData = async () => {
  const handleError = (err) => {
    console.log('Pears data error:', err);
    return { data: [] };
  };
  const [{ data: sites }, { data: projects }, { data: languages }] = await Promise.all([
    queryDataMart('SELECT name, address, city, state_abbreviation, zip_code, setting, is_active FROM site').catch(handleError),
    queryDataMart(`
      SELECT
          cqo.text
      FROM
          custom_question_option AS cqo
      WHERE
          EXISTS (
              SELECT 1
              FROM
                  custom_form_custom_question AS cfcq
              INNER JOIN
                  custom_question_program_area AS cqpa ON cfcq.custom_question_id = cqpa.custom_question_id
              WHERE
                  cqo.custom_question_id = cfcq.custom_question_id
                  AND cfcq.custom_question_id IN (
                      SELECT id
                      FROM custom_question
                      WHERE display_slug = '${process.env.REACT_APP_SPECIAL_PROJECTS_ID}'
                  )
                  AND cqpa.program_area_id = 1010 -- SNAP-ED program area ID
          );
   `).catch(handleError),
    queryDataMart('SELECT name, slug FROM list_language').catch(handleError),
  ]);
  const { partnerSites, mainOffices } = sites.reduce((acc, site) => {
    const name = `${site.name} (${site.address}, ${site.city}, ${site.state_abbreviation} ${site.zip_code})`;
    if (!acc.partnerSites.includes(name)) {
      acc.partnerSites.push(name);
    }
    if ((site.name.includes('U of I Extension -') || site.name.includes('U of I at Chicago - CPHP office UIC'))
      && site.is_active === 1
      && site.setting === 'Extension offices'
      && !acc.mainOffices.includes(name)
    ) {
      acc.mainOffices.push(name);
    }
    return acc;
  }, { partnerSites: [], mainOffices: []});
  return {
    partners: partnerSites.sort(),
    main_offices: mainOffices.sort(),
    special_projects: projects.map((p) => p.text).sort(
      (a, b) => {
        if (a === 'None') {
          return -1;
        } else if (b === 'None') {
          return 1;
        }
        return a.localeCompare(b);
      }),
    languages,
  };
};

const shortCodeIsUniqueEvent = async (shortCode) => {
  const events = await API.graphql(graphqlOperation(listEvents, { filter: { short_code: shortCode }}));
  return events.data.listEvents.items.length === 0;
};

export const generateUniqueShortCodeEvent = async () => {
  let shortCode = generateShortCode();
  const unique = await shortCodeIsUniqueEvent(shortCode);
  if (unique) {
    return shortCode;
  } else {
    return await generateUniqueShortCodeEvent();
  }
};

const shortCodeIsUnique = async (shortCode) => {
  const sessions = await API.graphql(graphqlOperation(listSessions, { filter: { short_code: { eq: shortCode }}}));
  return sessions.data.listSessions.items.length === 0;
};

export const generateUniqueShortCode = async () => {
  let shortCode = generateShortCode();
  const unique = await shortCodeIsUnique(shortCode);
  if (unique) {
    return shortCode;
  } else {
    return await generateUniqueShortCode();
  }
};
