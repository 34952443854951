import React, {useContext} from 'react';
import {List, ListItem, ListItemButton, ListItemText, styled} from '@mui/material';
import {Link} from 'react-router-dom';
import UserContext from '../../../lib/contexts/userContext';
import { Roles } from '../../../lib/constants';

const NavMenu = ({ showHome = false, listStyles = {}, onLinkClick = null }) => {
  const { isLoggedIn, signOut, isSSO, hasRole } = useContext(UserContext);

  const NavLink = ({ to, text, onClick, disabled = false }) => (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={to || ''} disabled={disabled} onClick={() => {
        if (onClick) onClick();
        if (onLinkClick) onLinkClick();
      }}>
        <ListItemText primary={text} sx={{ color: 'ilblue.main' }}/>
      </ListItemButton>
    </ListItem>
  );

  const NavList = styled(List)(listStyles);

  const eventsManagerEnabled = process.env.ENV !== 'production';

  return (
    <>
      <NavList dense={true} variant="nav" className={'nav'}>
        {hasRole(Roles.USER) && (
          <>
            {showHome && <NavLink to={'/'} text={'Home'} />}
          </>
        )}
        {(isLoggedIn() && hasRole(Roles.USER)) && (
          <>
            <NavLink to="/member/profile" text="My Profile"/>
            <NavLink to="/member/activities" text="My Activities"/>
            <NavLink to="/checkin" text="Check In"/>
            {!isSSO() && (
              <NavLink to="/member/password" text="Change Password"/>
            )}
          </>
        )}
        {(isLoggedIn() && hasRole([Roles.STAFF, Roles.ADMIN, Roles.SUPER_ADMIN])) && (
          <>
            {eventsManagerEnabled && (
              <>
                <NavLink text="Events Manager" disabled />
                <List sx={{ marginLeft: '8px' }}>
                  <NavLink to="/events" text="Events" />
                  <NavLink to="/sessions/upcoming" text="Sessions - Upcoming" />
                  <NavLink to="/sessions/past" text="Sessions - Past" />
                </List>
              </>
            )}
            {!isSSO() && (
              <>
                <NavLink to="/member/profile" text="My Profile"/>
                <NavLink to="/member/password" text="Change Password"/>
              </>
            )}
          </>
        )}
        {!isLoggedIn() && (
          <>
            <NavLink to="/signup" text="Sign Up" />
            <NavLink to="/login" text="Log In" />
            <NavLink to="/checkin" text="Check In" />
          </>
        )}
      </NavList>
      {/*<Divider className={'nav'} />*/}
      {isLoggedIn() && (
        <NavList variant="nav" dense={true} className={'nav'}>
          <NavLink onClick={() => signOut()} text="Sign Out"/>
        </NavList>
      )}
    </>
  );
};

export default NavMenu;
