import React from 'react';
import { PatternFormat } from 'react-number-format';


const PhoneMask = React.forwardRef(
  function PatternFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="(###) ###-####"
      />
    );
  },
);

export default PhoneMask;
