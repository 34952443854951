/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLessonSeries = /* GraphQL */ `
  mutation CreateLessonSeries(
    $input: CreateLessonSeriesInput!
    $condition: ModelLessonSeriesConditionInput
  ) {
    createLessonSeries(input: $input, condition: $condition) {
      id
      curriculum_name
      lesson_name
      snap_ed
      efnep
      cphp
      points
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLessonSeries = /* GraphQL */ `
  mutation UpdateLessonSeries(
    $input: UpdateLessonSeriesInput!
    $condition: ModelLessonSeriesConditionInput
  ) {
    updateLessonSeries(input: $input, condition: $condition) {
      id
      curriculum_name
      lesson_name
      snap_ed
      efnep
      cphp
      points
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLessonSeries = /* GraphQL */ `
  mutation DeleteLessonSeries(
    $input: DeleteLessonSeriesInput!
    $condition: ModelLessonSeriesConditionInput
  ) {
    deleteLessonSeries(input: $input, condition: $condition) {
      id
      curriculum_name
      lesson_name
      snap_ed
      efnep
      cphp
      points
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      description
      internal_only
      has_wifi
      start_date
      start_time
      end_time
      duration_in_seconds
      points
      event_type
      phone
      street_address
      zipcode
      city
      county
      state
      booth_topic_name
      curriculum_name
      lesson_name
      first_name
      middle_name
      last_name
      email
      event_openness
      location_name
      pears_program_id
      short_code
      checked_in_users {
        nextToken
      }
      qr_code
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      description
      internal_only
      has_wifi
      start_date
      start_time
      end_time
      duration_in_seconds
      points
      event_type
      phone
      street_address
      zipcode
      city
      county
      state
      booth_topic_name
      curriculum_name
      lesson_name
      first_name
      middle_name
      last_name
      email
      event_openness
      location_name
      pears_program_id
      short_code
      checked_in_users {
        nextToken
      }
      qr_code
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      description
      internal_only
      has_wifi
      start_date
      start_time
      end_time
      duration_in_seconds
      points
      event_type
      phone
      street_address
      zipcode
      city
      county
      state
      booth_topic_name
      curriculum_name
      lesson_name
      first_name
      middle_name
      last_name
      email
      event_openness
      location_name
      pears_program_id
      short_code
      checked_in_users {
        nextToken
      }
      qr_code
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      points {
        nextToken
      }
      events_attended {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      points {
        nextToken
      }
      events_attended {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      points {
        nextToken
      }
      events_attended {
        nextToken
      }
      activities {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserScore = /* GraphQL */ `
  mutation CreateUserScore(
    $input: CreateUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    createUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserScore = /* GraphQL */ `
  mutation UpdateUserScore(
    $input: UpdateUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    updateUserScore(input: $input, condition: $condition) {
      id
      points
      userID
    }
  }
`;
export const updateUserScoreWithUser = /* GraphQL */ `
  mutation UpdateUserScore(
    $input: UpdateUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    updateUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserScore = /* GraphQL */ `
  mutation DeleteUserScore(
    $input: DeleteUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    deleteUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserActivities = /* GraphQL */ `
  mutation CreateUserActivities(
    $input: CreateUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    createUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        createdAt
        updatedAt
        owner
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserActivities = /* GraphQL */ `
  mutation UpdateUserActivities(
    $input: UpdateUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    updateUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        createdAt
        updatedAt
        owner
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserActivities = /* GraphQL */ `
  mutation DeleteUserActivities(
    $input: DeleteUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    deleteUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        createdAt
        updatedAt
        owner
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserEvents = /* GraphQL */ `
  mutation CreateUserEvents(
    $input: CreateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    createUserEvents(input: $input, condition: $condition) {
      id
      eventID
      userID
      event {
        id
        description
        start_date
        start_time
        end_time
        duration_in_seconds
        points
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        qr_code
        createdAt
        updatedAt
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserEvents = /* GraphQL */ `
  mutation UpdateUserEvents(
    $input: UpdateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    updateUserEvents(input: $input, condition: $condition) {
      id
      eventID
      userID
      event {
        id
        description
        start_date
        start_time
        end_time
        duration_in_seconds
        points
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        qr_code
        createdAt
        updatedAt
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserEvents = /* GraphQL */ `
  mutation DeleteUserEvents(
    $input: DeleteUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    deleteUserEvents(input: $input, condition: $condition) {
      id
      eventID
      userID
      event {
        id
        description
        start_date
        start_time
        end_time
        duration_in_seconds
        points
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        qr_code
        createdAt
        updatedAt
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
