import axios from 'axios';
// import AWS from 'aws-sdk';
import { CognitoIdentityProviderClient, ListUsersInGroupCommand } from '@aws-sdk/client-cognito-identity-provider';
import awsmobilep from '../aws-exports';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { defaultComparator, getFullName } from './utils';
import { deleteEvent } from '../graphql/mutations';

const RELAY_URL = process.env.REACT_APP_RELAY_URL;
const RELAY_KEY = process.env.REACT_APP_RELAY_KEY;
const DATAMART_RELAY = process.env.REACT_APP_DATAMART_RELAY;

/**
 * Retrieve the user role for a provided email address from myINEP.
 *
 * @param email
 */
export const getUserRole = (email) => {
  const userMetaUrl = process.env?.REACT_APP_MYINEP_USER_META_URL;
  if (!userMetaUrl) {
    return null;
  }
  return axios.get(`${userMetaUrl}?email=${encodeURIComponent(email)}`).catch((err) => {
    console.log('[api] myINEP user metadata error', err);
    return null;
  });
};

/**
 * Get list of staff users from myINEP
 *
 * @returns {Promise<axios.AxiosResponse<any>>|null}
 */
export const getInepUsers = () => {
  const usersUrl = process.env?.REACT_APP_MYINEP_USERS_URL;
  if (!usersUrl) {
    return null;
  }
  return axios.get(usersUrl).catch((err) => {
    console.log('[api] myINEP user list error', err);
    return null;
  });
};

/**
 * Gets list of all Cognito users in one of the admin groups.
 *
 * @returns Promise<array>
 */
export const getCognitoUsers = async () => {
  const userPool = awsmobilep.aws_user_pools_id;
  const client = new CognitoIdentityProviderClient({
    region: 'us-east-2',
    credentials: Auth.Credentials.get(),
  });
  const groupNames = ['Staff', 'Administrator', 'SuperAdministrator'];
  const promises = await Promise.all(groupNames.map((group) => new Promise(async (resolve) => {
    const input = {
      UserPoolId: userPool,
      GroupName: group,
    };
    const command = new ListUsersInGroupCommand(input);
    const response = await client.send(command);

    const userData = response.Users.map((user) => {
      const firstName = user.Attributes.find((attr) => attr.Value && ['first_name', 'custom:first_name', 'given_name'].includes(attr.Name));
      const lastName = user.Attributes.find((attr) => attr.Value && ['last_name', 'custom:last_name', 'family_name'].includes(attr.Name));
      const name = user.Attributes.find((attr) => attr.Value && attr.Name === 'name');
      const email = user.Attributes.find((attr) => attr.Value && attr.Name === 'email');
      return {
        firstName: firstName?.Value,
        lastName: lastName?.Value,
        name: name?.Value,
        email: email?.Value,
      };
    });
    resolve(userData);
  })));
  return promises.reduce((acc, data) => {
    acc.push(...data);
    return acc;
  }, []);
};

/**
 * Get list of staff users from both myINEP and Cognito.
 */
export const getStaffUsers = async (sort = true) => {
  const results = await Promise.all([
    getInepUsers().then((result) => (result?.data || []).map((u) => ({
      email: u['Email Address'],
      firstName: u['First Name'],
      lastName: u['Last Name'],
      role: u['Role'],
    }))).catch((err) => console.error(err)),
    getCognitoUsers().catch((err) => console.error(err)),
  ]);
  const staff = [...results[0], ...results[1]].map((u) => {
    if (!u.name) {
      u.name = getFullName(u);
    }
    return u;
  });
  if (sort) {
    staff.sort(defaultComparator('asc', 'name'));
  }
  return staff;
};

/**
 * Retrieve the name of a staff member from myINEP or Cognito.
 * Multiple names can be returned by passing an array of strings and will be returned
 * as an object keyed by the email address. Not found users will have
 * a value of the email address that couldn't be found.
 *
 * @param emails
 * @returns {Promise<*>}
 */
export const getStaffNames = async (emails) => {
  const staff = await getStaffUsers();
  const getUser = (email) => staff.find((u) => u.email === email);
  const users = (Array.isArray(emails) ? emails : [emails]).reduce((acc, email) => {
    const user = getUser(email);
    acc[email] = user?.name || email;
    return acc;
  }, {});
  if (emails.length === 1) {
    return users[emails];
  }
  return users;
};

export const queryDataMart = async (query) => axios.post(
  DATAMART_RELAY,
  {
    body: {
      query,
    }
  }, {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + RELAY_KEY,
  },
});

const invokeRelay = async (payload) => axios.post(RELAY_URL, payload, {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + RELAY_KEY,
  },
});

/**
 * Add contact to a specific group (county) list.
 * @param group (county)
 * @param phone
 * @param firstName
 * @param lastName
 * @param email
 * @return response
 */
export const simpTextAddContact = async ({ group, phone, firstName, lastName, email }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    group,
    phone,
    firstName,
    lastName,
    email,
    // comment: 'SOME_STRING_VALUE',
    // birthday: 'SOME_STRING_VALUE',
    // customField: 'SOME_STRING_VALUE'
  };
  const url = 'https://app2.simpletexting.com/v1/group/contact/add';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

/**
 * Remove contact from the UNSUBSCRIBE list (done when adding contacts).
 */
export const simpTextRemoveUnsub = ({ phone }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    phone,
  };
  const url = 'https://app2.simpletexting.com/v1/contacts/blocklist/remove';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

/**
 * Add contact to the UNSUBSCRIBE list (removes contact).
 */
export const simpTextAddUnsub = ({ phone }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    phone,
  };
  const url = 'https://app2.simpletexting.com/v1/contacts/blocklist/add';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

export const graphQuery = (
  queryString,
  params,
  authMode = 'AMAZON_COGNITO_USER_POOLS'
) => API.graphql({
  ...graphqlOperation(queryString, params),
  authMode,
});
