export const getUserWithActivities = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      points {
        nextToken
      }
      events_attended {
        nextToken
      }
      activities {
        items {
          id
          userID
          activityID
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;