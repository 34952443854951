import { Pages } from '../../../../lib/constants';
import Page from '../../Page';
import CreateEvent from './CreateEvent';

export const CreateEventPage = () => {
  return (
    <Page
      title="Create Event"
      sectionName="events"
      navPhoto={{ image: '/images/events/CREATE_EVENT_PHOTO.jpg' }}
      pageId={Pages.EVENT_CREATE}
      requireAuth
    >
      <CreateEvent />
    </Page>
  )
};

export default CreateEventPage;
