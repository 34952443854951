import { visuallyHidden } from '@mui/utils';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';

const MuiTableHead = (props) => {
  const { headCells, order, orderBy, onRequestSort, noWrap } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  /**
   *
   * @param numeric
   * @param align {string}
   * @returns {string[left|right|center]}
   */
  const getAlign = ({ numeric = false, align = 'left' }) => {
    if (numeric) {
      return 'right';
    }
    return align;
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={getAlign(headCell)}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              textWrap: noWrap ? 'nowrap' : 'normal',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
};

export default MuiTableHead;
