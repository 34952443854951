import React, {useState, useEffect, useContext} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import {createUserActivities, updateUser} from '../../../graphql/mutations';
import {scoreByUser} from '../../../queries';
import {getUserWithActivities} from '../../../lib/customQueries';
import {updateUserScore} from '../../../mutations';
import UserContext from '../../../lib/contexts/userContext';
import {FormControlLabel, ListItem, Radio, RadioGroup, Typography, useTheme} from '@mui/material';
import { paletteMains, tenDigit } from '../../../lib/utils';
import {Stack} from '@mui/system';
import { simpTextAddContact, simpTextAddUnsub, simpTextRemoveUnsub } from '../../../lib/api';

const ActivityItem = ({ activity, userActivities, onEnroll, setUserScore }) => {
  const { userState: { userRecord }, updateUserRecord } = useContext(UserContext);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const isEnrollable = (activity.id !== '1'); // Users cannot manually enroll/un-enroll from signing up on the app (activity 1)

  const theme = useTheme();
  const palette = paletteMains(theme);

  const userID = userRecord?.id;

  const getSimpTextPhone = () => tenDigit(userRecord.phone_number);

  useEffect(() => {
    if (activity.id === '1') {
      setIsEnrolled(true);
    } else if (activity.id === '2') {
      setIsEnrolled(!!userRecord?.allow_enews);
    } else if (activity.id === '3') {
      setIsEnrolled(!!userRecord?.allow_text_program);
    } else if (userActivities && userActivities.length > 0) {
      const foundActivity = userActivities.find((userActivity) => activity.id === userActivity.activityID);
      setIsEnrolled(!!foundActivity);
    }
  }, [activity.id, userActivities, userRecord?.allow_enews, userRecord?.allow_text_program]);

  const updateUserRecordQuery = async (propKey, value) => {
    const updateResult = await API.graphql({
      ...graphqlOperation(updateUser, {
        input: {
          id: userID,
          [propKey]: value
        }
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    const updatedUser = updateResult?.data?.updateUser;
    if (updatedUser?.id) {
      updateUserRecord(updatedUser);
    }
  };

  const modifyUserScore = async (direction = 1) => {
    // Retrieve the user score and update it with the activity points
    const scoreResult = await API.graphql(graphqlOperation(scoreByUser, {
      userID,
    }));
    if (scoreResult && scoreResult.data.scoreByUser.items.length) {
      const userScore = scoreResult.data.scoreByUser.items[0];
      let score = userScore.points + (direction * activity.points);
      await API.graphql({
        ...graphqlOperation(updateUserScore, {
          input: {
            id: userScore.id,
            points: score,
          }
        }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      setUserScore(score);
    }
  };

  const simpTextAdd = async () => {
    try {
      await simpTextRemoveUnsub({ phone: getSimpTextPhone() });
      await simpTextAddContact({
        group: userRecord.county,
        phone: getSimpTextPhone(),
        firstName: userRecord.first_name,
        lastName: userRecord.last_name,
        email: userRecord.email,
      });
      return true;
    } catch (err) {
      console.log('SimpText remUnsub/add error', err);
      return false;
    }
  };

  const handleRadioOnChange = async (event) => {
    try {
      const propKey = event.target.name === 'enews' ? 'allow_enews' : 'allow_text_program';
      if (isEnrolled) {
        // Un-enroll user/update field in User table
        setIsEnrolled(false);

        if (activity.id === '3') {
          // This is unenrollment from the TEXT PROGRAM
          try {
            await simpTextAddUnsub({ phone: getSimpTextPhone() });
            await updateUserRecordQuery(propKey, false);
          } catch (err) {
            console.log('SimpText unsub error', err);
            setIsEnrolled(true);
          }
        } else {
          await updateUserRecordQuery(propKey, false);
        }
      } else {
        // Enroll user/create UserActivity
        setIsEnrolled(true);

        // Find existing user activity for this activity
        const result = await API.graphql(graphqlOperation(getUserWithActivities, {
          id: userID
        }));
        const userActivities = result.data.getUser?.activities?.items || [];
        const foundActivity = !!userActivities.find((a) => a.activityID === activity.id);

        if (!foundActivity) {
          // Create the user activity since there was none found

          // If this is the text program, attempt to subscribe first via SimpleTexting
          let simpTextResult = true;
          if (activity.id === '3') {
            simpTextResult = await simpTextAdd();
          }

          if (simpTextResult) {
            await API.graphql(graphqlOperation(createUserActivities, {
              input: {
                activityID: activity.id,
                userID: userRecord.id
              }
            }));

            // Only modify points here for the signup activities (enews [2] and text program [3])
            if (['2', '3'].includes(activity.id)) {
              await modifyUserScore();
            }

            // Enroll user/update field in User table
            await updateUserRecordQuery(propKey, true);
          } else {
            setIsEnrolled(false);
          }
        } else if (activity.id === '3') {
          // They don't get points, but we still subscribe them to SimpleTexting (text program)
          const simpTextResult = await simpTextAdd();
          if (simpTextResult) {
            // Enroll user/update field in User table
            await updateUserRecordQuery(propKey, true);
          } else {
            setIsEnrolled(false);
          }
        } else {
          // Enroll user/update field in User table
          await updateUserRecordQuery(propKey, true);
        }

        onEnroll();
      }
    } catch (error) {
      console.log('Error: ', error);
      setIsEnrolled(isEnrolled);
    }
  };

  return (
    <ListItem disablePadding>
      <Stack>
        <Typography variant="h6" component="h6" fontWeight="bold">
          {activity.title.toUpperCase()}
        </Typography>
        <Typography variant="h7" component="div" sx={{ color: palette.green }}>
          {activity.points} Points
        </Typography>
        <Typography variant="body1" component="div" sx={{ wordWrap: 'break-word' }}>
          {activity.description}
        </Typography>
        {isEnrollable &&
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={activity.activity_code}
            value={isEnrolled ? 1 : 0}
            onChange={handleRadioOnChange}
            row
            sx={{
              justifyContent: 'space-around',
              maxWidth: '350px',
            }}
          >
            <FormControlLabel value="0" control={<Radio />} label="Not Enrolled" />
            <FormControlLabel value="1" control={<Radio />} label="Enrolled" />
          </RadioGroup>
        }
      </Stack>
    </ListItem>
  );
};

export default ActivityItem;
