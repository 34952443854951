import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { generateUniqueShortCode, getBoothTopics, getPearsData, listEventsWithLessons } from '../../eventFunctions';
import SessionForm from '../SessionForm';
import { API, graphqlOperation } from 'aws-amplify';
import { AbilityContext, Subjects, toGraphQuery } from '../../../../../lib/permissions';
import { useAbility } from '@casl/react';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../../../../../lib/contexts/userContext';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import { createFilter, generateQRCode, scrollToAlert, sortDisplayOrder } from '../../../../../lib/utils';
import { createSession } from '../../../../../graphql/mutations';
import { subject } from '@casl/ability';
import moment from '../../../../../lib/moment';

const CreateSession = () => {
  const {
    setPageMessage,
    setPageErrorMessage,
    updateCrumbLabel,
  } = useContext(PageContext);

  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const valuesSetState = useState(null);
  const [, setValuesSet] = valuesSetState;

  const { userState: { userRecord }, authenticated, getStaffUsersWithCurrentUser } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const navigate = useNavigate();

  const { eventID } = useParams();

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      eventID: null,
      lesson: null,
      lessonID: null,
      curriculumID: null,
      start: null,
      end: null,
      delivery_method: null,
      delivery_url: null,
      delivery_language: null,
      material_language: null,
      delivery_location: null,
      other_details: null,
      has_wifi: null,
      demonstration: null,
      recipes: null,
      code: null,
      updated_by: null,
    }
  });

  const resetForm = () => {
    // Reset form hook
    form.reset();
    // Clear page message after 5 seconds
    setTimeout(() => setPageMessage(''), 5000);
    // Tell the form component that values have been reset
    setValuesSet(null);
  };

  const fetchEvent = useCallback(async (eventID, filterRules = {}) => {
    try {
      // Filter the event based on user permissions (if they can't view it, it won't return)
      const filter = createFilter(eventID, filterRules);
      const result = await API.graphql({
        ...graphqlOperation(listEventsWithLessons, { filter }),
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      const [eventData] = result.data.listEvents.items;
      return eventData;
    } catch (error) {
      console.log('Error: ', error);
      return null;
    }
  }, []);

  /** Pull curricula and staff users on load **/
  useEffect(() => {
    if (!eventID || !authenticated) {
      return;
    }
    // Try to get the query filter first in case the userRole hasn't been set and permissions haven't been updated
    const filter = toGraphQuery(ability, 'create', Subjects.SESSION);
    if (ability.cannot('create', Subjects.SESSION) && !filter) {
      // Staff that can only edit their own events will NOT have the edit EVENT permission because it's conditional
      // Therefore there must be a non-null filter
      return;
    }

    let active = true;

    Promise.all([
      fetchEvent(eventID).then((eventData) => {
        if (!active) {
          return;
        }
        if (eventData?.id && eventData?.event_name) {
          updateCrumbLabel(eventData.id, eventData.event_name);
          return eventData;
        }
      }),
      getBoothTopics(),
      getStaffUsersWithCurrentUser(),
      getPearsData(),
    ]).then(([event, boothTopics, staff, pears]) => {
      if (!active) {
        return;
      }
      pears.languages = pears.languages.map((lang) => ({ id: lang.slug, label: lang.name }));
      pears.languageLabels = pears.languages.map((lang) => (lang.label));
      sortDisplayOrder(event?.curriculum?.lessons?.items, 'lesson_name');
      setFormData({
        event,
        boothTopics,
        staff,
        pears,
      });
    });

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, userRecord, ability, eventID, fetchEvent]);

  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    // Generate a unique short code for the event check-in process
    const shortCode = await generateUniqueShortCode();
    // Generate a QR code from the event short code
    const qrCode = await generateQRCode(shortCode);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'lesson':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          break;
        case 'start':
        case 'end':
          acc[key] = moment(value).toISOString(true);
          break;
        case 'has_wifi':
        case 'delivery_method':
        case 'delivery_url':
        case 'delivery_location':
        case 'other_details':
        case 'recipes':
          acc[key] = value?.id || value;
          break;
        case 'delivery_language':
        case 'material_language':
          acc[key] = value;
          break;
        case 'demonstration':
          acc[key] = value === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      eventID,
      updated_by: userRecord.email,
      created_by: userRecord.email,
      qr_code: qrCode,
      short_code: shortCode,
    });

    // Staff users can only assign events to themselves
    if (ability.cannot('create', subject(Subjects.SESSION, { ...input, event: formData.event }))) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to create sessions for events created by other staff members.');
      scrollToAlert();
      return false;
    }
    try {
      const result = await API.graphql({
        ...graphqlOperation(createSession, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      setSubmitting(false);
      navigate(`/events/${formData.event.id}/${result.data.createSession.id}`, { state: { message: 'Session created' }});
    } catch (error) {
      console.log('Error creating session: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error creating session. Please try again.');
      scrollToAlert();
    }
  };

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        { formData === false && (
          <>You do not have access, or the event was not found.</>
        )}
        { formData === null && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
        { !!formData && (
          <SessionForm
            form={form}
            event={formData.event}
            session={false}
            pearsData={formData.pears}
            staffUsers={formData.staff}
            boothTopics={formData.boothTopics}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateSession;
