import {API, graphqlOperation} from 'aws-amplify';
import { getUser } from '../../../graphql/queries';

const fetchUserEvents = async (userID) => {
  try {
    const user = await API.graphql(graphqlOperation(getUser, { id: userID }));
    const checkedInEvents = user.data.getUser.events_attended.items;

    if (checkedInEvents.length > 0) {
      let events = [];
      for (const userEvent of checkedInEvents) {
        events.push(userEvent.event);
      }

      // Order the events in place by start date desc.
      events.sort((a, b) => {
        return new Date(b.start_date) - new Date(a.start_date);
      });

      return events;
    } else {
      return [];
    }
  } catch (error) {
    console.log('Error: ', error)
  }
}

export {
  fetchUserEvents
}
