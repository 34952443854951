import { Button, CircularProgress, FormGroup, FormLabel, Link, List, ListItem, Typography } from '@mui/material';
import {
  AutocompleteElement,
  DateTimePickerElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextareaAutosizeElement,
  TextFieldElement
} from 'react-hook-form-mui';
import CheckboxButtonGroup from '../../../common/HookFormMui/CheckboxButtonGroup';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import UserContext from '../../../../lib/contexts/userContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from '../../../../lib/moment';
import useErrorHandling from '../../../common/hooks/useErrorHandling';

const SessionForm = ({
  form,
  session,
  event,
  pearsData,
  staffUsers,
  boothTopics,
  submitting,
  onSubmit,
  valuesSetState,
}) => {
  const { userState: { userRecord  } } = useContext(UserContext);

  const [wasSubmitting, setWasSubmitting] = useState(false);
  const [valuesSet, setValuesSet] = valuesSetState;

  const pickerOpenStart = useRef(false);

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = form;

  const deliveryMethodWatch = watch('delivery_method');
  const lessonWatch = watch('lesson');
  const demoWatch = watch('demonstration');

  const toUserOption = ({ email, name }) => ({
    id: email,
    label: name ? `${name} (${email})` : email,
  });

  const toLessonOption = ({ id, curriculum_name, booth_topic, lesson_name, audience }) => ({
    id,
    label: curriculum_name || booth_topic || lesson_name,
    audience
  });

  useErrorHandling({ errors, isSubmitting, wasSubmitting, setWasSubmitting });

  useEffect(() => {
    const getUser = (email) => staffUsers.find((u) => u.email === email);
    if (session === false) {
      // No session means we are creating one
      if (!valuesSet) {
        if (event.event_type === 'BOOTH') {
          setValue('delivery_method', ['In Person']);
          console.log('delivery method set');
        }
        setValuesSet(true);
      }
      return;
    } else if (!session) {
      console.log('session not found');
      // session was not found
      return;
    }
    const getLang = (langId) => {
      const lang = pearsData.languages.find((l) => (l.id === langId || l.label === langId));
      return lang?.label || langId;
    };
    const values = {
      eventID: session.eventID,
      lessonID: session.lessonID,
      lesson: session.lessonID ? toLessonOption(session.lesson) : null,
      curriculumID: session.curriculumID,
      curriculum: session.curriculumID ? toLessonOption(session.curriculum) : null,
      start: moment(session.start),
      end: moment(session.end),
      delivery_method: (event.event_type === 'BOOTH' ? ['In Person'] : session.delivery_method),
      delivery_url: session.delivery_url,
      delivery_language: session.delivery_language.map(getLang),
      material_language: session.material_language.map(getLang),
      delivery_location: session.delivery_location,
      other_details: session.other_details,
      has_wifi: session.has_wifi,
      demonstration: session.demonstration ? 'true' : 'false',
      recipes: session.recipes,
      code: session.code,
      updated_by: session?.updated_by ? toUserOption(getUser(session.updated_by)) : null,
    };
    Object.keys(values).forEach((key) => setValue(key, values[key]));
    setValuesSet(true);
  }, [pearsData.languages, session, setValue, staffUsers, userRecord.email, valuesSet]);

  const handleDeliveryMethodChange = (value) => {
    if (!value.includes('In Person')) {
      setValue('delivery_location', null);
    }
    if (!value.includes('Virtual/Live Online')) {
      setValue('delivery_url', null);
      setValue('other_details', null);
    }
  }
  const handleLessonChange = (value) => {
    if (value.audience === 'Youth') {
      setValue('has_wifi', null);
    }
  };
  const handleDemoChange = (value) => {
    if (value !== 'true') {
      setValue('recipes', null);
    }
  };

  const handleStartChange = (value) => {
    if (!pickerOpenStart.current) {
      handleStartAccept(value);
    } else {
      if (!value) return true;
      trigger('start').then();
      trigger('end').then();
    }
  };

  const handleStartAccept = (value) => {
    if (!value) return true;
    const eventEnd = getValues('end');
    let newEnd = (eventEnd && eventEnd.isValid()) ? eventEnd.clone() : null;
    if (!eventEnd && value && value.isValid()) {
      newEnd = value.clone();
      newEnd.add(1, 'hours');
      setValue('end', newEnd);
    } else if (newEnd && value && value.isValid()) {
      newEnd.set({
        year: value.get('year'),
        month: value.get('month'),
        date: value.get('date'),
      }).format();
      setValue('end', newEnd);
    }
    trigger('start').then();
    trigger('end').then();
  };

  const getFormField = (field) => (
    <ListItem sx={{ display: "list-item" }} key={`field-${field.props.id}`}>
      { field }
    </ListItem>
  );

  /**
   * Displays a loader until pearsData is not null.
   */
  const PearsElement = ({ id, name, children }) => (
    (pearsData === null ?
      <FormGroup
        id={id}
        name={name}
      >
        <FormLabel>{children.props.label}</FormLabel>
        <CircularProgress size="1.5rem" />
      </FormGroup>
      :
      <>{ children }</>
    )
  );

  const languages = pearsData?.languageLabels;

  const fields = [];

  if (event.event_type === 'CLASS') {
    const options = event?.curriculum?.lessons?.items?.map((c) => ({
      id: c.id,
      label: c.lesson_name,
      audience: c.audience,
    }));
    fields.push(
      <AutocompleteElement
        id="field-lesson"
        name="lesson"
        label={'What lesson will you be teaching?'}
        placeholder={'Choose curriculum'}
        textFieldProps={{ variant: 'standard' }}
        control={control}
        fullWidth
        required
        options={options}
        autocompleteProps={{
          onChange: handleLessonChange,
          isOptionEqualToValue: (option, newValue) => {
            return option?.id === newValue?.id;
          }
        }}
      />
    );
  } else if (event.event_type === 'BOOTH') {
    const options = boothTopics.map((c) => ({
      id: c.id,
      label: c.booth_topic,
      audience: c.audience,
    }));
    fields.push(
      <AutocompleteElement
        id="field-lesson"
        name="lesson"
        label={'Which booth topic will you be doing?'}
        placeholder={'Choose curriculum'}
        textFieldProps={{ variant: 'standard' }}
        control={control}
        fullWidth
        required
        options={options}
        autocompleteProps={{
          onChange: handleLessonChange,
          isOptionEqualToValue: (option, newValue) => {
            return option?.id === newValue?.id;
          }
        }}
      />
    );
  }

  fields.push(
    <DateTimePickerElement
      id="field-start"
      name="start"
      label={'What is the planned start date & time for this session?'}
      control={control}
      required
      validation={{
        validate: {
          range: (value) => {
            const endDate = getValues('end');
            if (!value || !endDate) return true;
            const valid = !value.isAfter(endDate);
            return valid || 'Start Date & Time must be less than End Date & Time';
          }
        }
      }}
      onOpen={() => { pickerOpenStart.current = true }}
      onClose={() => { pickerOpenStart.current = false }}
      onAccept={handleStartAccept}
      onChange={handleStartChange}
      inputProps={{
        variant: 'standard',
        fullWidth: true,
        id: 'field-start',
      }}
    />
  );

  fields.push(
    <DateTimePickerElement
      id="field-end"
      name="end"
      label={'What is the planned end date & time for this session'}
      helperText={'End Date & Time must be greater than Start Date & Time.'}
      control={control}
      required
      validation={{
        validate: {
          range: (value) => {
            const eventStart = getValues('start');
            if (!value || !eventStart) return true;
            const valid = value.isAfter(eventStart);
            return valid || 'End Date & Time must be greater than Start Date & Time';
          }
        }
      }}
      onChange={() => {
        trigger('start').then();
        trigger('end').then();
      }}
      inputProps={{
        variant: 'standard',
        fullWidth: true,
        id: 'field-end',
      }}
    />
  );

  fields.push(
    <CheckboxButtonGroup
      label="What method will be used to deliver this program? Select all that apply."
      id="field-delivery_method"
      name="delivery_method"
      control={control}
      required
      fullWidth
      readOnly={event.event_type === 'BOOTH'}
      onChange={handleDeliveryMethodChange}
      options={[
        'In Person',
        'Virtual/Live Online',
      ]}
    />
  );

  fields.push(
    <AutocompleteElement
      id="field-delivery_language"
      name="delivery_language"
      label="What language(s) will this session be delivered in? Select all that apply."
      textFieldProps={{
        variant: 'standard',
      }}
      control={control}
      fullWidth
      required
      multiple
      autocompleteProps={{
        isOptionEqualToValue: (option, newValue) => {
          return option === newValue;
        }
      }}
      options={languages}
    />
  );

  fields.push(
    <AutocompleteElement
      id="field-material_language"
      name="material_language"
      label="What language(s) will participant materials be provided in? Select all that apply."
      textFieldProps={{
        variant: 'standard',
      }}
      control={control}
      fullWidth
      required
      multiple
      autocompleteProps={{
        isOptionEqualToValue: (option, newValue) => {
          return option === newValue;
        }
      }}
      options={languages}
    />
  );

  /**
   * PEARS field
   */
  if (deliveryMethodWatch?.includes('In Person') || event.event_type === 'BOOTH') {
    fields.push(
      <PearsElement
        id="field-delivery_location"
        name="delivery_location"
      >
        <AutocompleteElement
          id="field-delivery_location"
          name="delivery_location"
          label="Where will this session be delivered?"
          textFieldProps={{
            variant: 'standard',
            helperText: <>If you cannot find a site, please email <Link href="mailto:uie-inepdocs@illinois.edu">uie-inepdocs@illinois.edu</Link> to request to add the site.</>
          }}
          control={control}
          fullWidth
          required
          options={pearsData?.partners || []}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option === newValue;
            }
          }}
        />
      </PearsElement>
    );
  }

  if (deliveryMethodWatch?.includes('Virtual/Live Online')) {
    fields.push(
      <TextFieldElement
        id="field-delivery_url"
        name="delivery_url"
        label="What is the URL or link for this session?"
        variant={'standard'}
        control={control}
        required
        fullWidth
      />
    );

    fields.push(
      <TextareaAutosizeElement
        id="field-other_details"
        name="other_details"
        label="Other details:"
        variant={'standard'}
        control={control}
        fullWidth
      />
    );
  }

  if (lessonWatch && lessonWatch?.audience !== 'Youth') {
    fields.push(
      <SelectElement
        id="field-has_wifi"
        name="has_wifi"
        label="Is WiFi available for you to use at this site?"
        placeholder={'Select WiFi availability'}
        variant={'standard'}
        control={control}
        fullWidth
        required
        options={[
          { label: 'Yes', id: 'Yes' },
          { label: 'No', id: 'No' },
          { label: 'I don\'t know', id: 'I don\'t know' },
        ]}
      />
    );
  }

  fields.push(
    <RadioButtonGroup
      id="field-demonstration"
      name="demonstration"
      label="Will this session include a food demonstration / taste test?"
      variant={'standard'}
      control={control}
      fullWidth
      required
      onChange={handleDemoChange}
      options={[
        { label: 'Yes', id: 'true' },
        { label: 'No', id: 'false' },
      ]}
    />
  );

  if (demoWatch === 'true') {
    fields.push(
      <TextFieldElement
        id="field-recipes"
        name="recipes"
        label="What recipe(s) do you plan to use?"
        variant={'standard'}
        control={control}
        required
        fullWidth
      />
    );
  }

  return (
    <Box width={'100%'}>
      { !!valuesSet && (
        <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{ className: 'mui' }}>
          <Typography mt={'15px'} mb={'15px'} sx={{ fontWeight: 600 }} component="div">
            <label className={'form-text required'}>Required Fields</label>
          </Typography>
          <Grid container columns={12} spacing={2}>
            <Grid xs={12} sm={10} md={8}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <List sx={{
                    listStyle: 'decimal',
                    paddingLeft: 2,
                  }}>
                  { fields.map(getFormField) }
                </List>
              </LocalizationProvider>
            </Grid>

            <Grid xs={12} sm={10} md={8}>
              <Button
                id="button-submit"
                disabled={submitting}
                type={'submit'}
                variant="contained"
                size="small"
                sx={{ marginTop: '20px' }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      )}
    </Box>
  );
};

export default SessionForm;
