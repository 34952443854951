import React from 'react';
import UserEventItem from './UserEventItem';
import {Box} from '@mui/system';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import {paletteMains} from '../../../lib/utils';

const UserEventHistory = ({ events = [], subTotals }) => {
  const theme = useTheme();
  const palette = paletteMains(theme);

  const HeadCell = ({ children, ...rest }) => <TableCell {...rest}>
    <Typography variant="h7" sx={{ color: palette.blue, fontWeight: 600 }}>
      { children }
    </Typography>
  </TableCell>;

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold">
        MYEAT.MOVE.SAVE EVENTS
      </Typography>
      <Typography variant="body2">
        Earn 100 points for attending class events and 50 points for booth events.
      </Typography>
      <TableContainer sx={{ maxWidth: '350px' }}>
        <Table size="small" sx={{
          '& th, & td': { border: 'none', paddingLeft: 0, paddingRight: 0 },
          '& th:first-of-type, & td:first-of-type': { paddingLeft: 0 },
          '& th:last-of-type, & td:last-of-type': { paddingRight: 0 },
        }}>
          <TableHead>
            <TableRow>
              <HeadCell>Date</HeadCell>
              <HeadCell align="center">Info</HeadCell>
              <HeadCell align="center">Points</HeadCell>
              <HeadCell>Type</HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              events.map((event) =>
                <UserEventItem
                  key={event.id}
                  event={event}
                />)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserEventHistory;
