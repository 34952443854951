import Grid from '@mui/material/Unstable_Grid2';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui';
import {Button, CircularProgress} from '@mui/material';
import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {useForm} from 'react-hook-form';

const ResetPasswordForm = ({ setPageMessage, setPageErrorMessage }) => {

  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    control,
  } = useForm({
    mode: 'onSubmit',
  });

  const handleReset = ({ email }) => {
    setSubmitting(true);
    setPageMessage('');
    setPageErrorMessage('');

    Auth.forgotPassword(email)
      .then(() => {
        setSubmitting(false);
        setPageMessage('A reset password email has been sent to your email address. Please check your email and follow the instructions in the email.');
      })
      .catch((err) => {
        setPageErrorMessage(err.message);
        setSubmitting(false);
      });
  };

  return (
    <FormContainer onSuccess={handleSubmit(handleReset)} FormProps={{ className: 'mui' }}>
      <Grid container spacing={3}>
        {submitting && (
          <Grid xs={12} display="flex" justifyContent="center">
            <CircularProgress/>
          </Grid>
        )}
        {!submitting && (
          <>
            <Grid xs={12}>
              <TextFieldElement
                id="field-email"
                name="email"
                label={'Email'}
                variant={'standard'}
                placeholder="Enter your email here"
                control={control}
                autoComplete="email"
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <Button
                id="button-submit"
                variant="contained"
                size="small"
                type="submit"
                fullWidth
                sx={{ marginTop: '20px' }}
              >
                Send Link
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </FormContainer>
  );
};

export default ResetPasswordForm;
