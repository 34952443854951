import { Pages } from '../../../../lib/constants';
import Page from '../../Page';
import ViewEvents from './ViewEvents';

export const ViewEventsPage = () => {
  return (
    <Page
      title="Events"
      sectionName="events"
      pageId={Pages.EVENTS}
      requireAuth
    >
      <ViewEvents />
    </Page>
  )
};

export default ViewEventsPage;
