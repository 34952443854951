// ReactJS
import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

// material ui
import {Paper} from '@mui/material';

// amplify
import {API, graphqlOperation} from 'aws-amplify';

import {listEvents} from '../../../graphql/queries';
// custom
import UserContext from '../../../lib/contexts/userContext';
import Page from '../Page';

const OldCurrentEvent = (props) => {
  const [events, setEvents] = useState([]);
  const [eventID, setEventID] = useState('');
  const { userState: { userRecord } } = useContext(UserContext);

  const queryString = new URLSearchParams(useLocation().search).get('eventID');
  useEffect(() => {
    async function fetchEvents(eventID) {
      try {
        const filter = {};
        if (eventID) {
          filter.id = { eq: eventID };
        } else {
          filter.first_name = { eq: userRecord?.first_name };
          filter.last_name = { eq: userRecord?.last_name };
        }
        const eventsData = await API.graphql(graphqlOperation(listEvents, { filter: filter }));
        return eventsData.data.listEvents.items;
      } catch (error) {
        console.log('Error: ', error);
      }
    }

    async function setEvent(queryString) {
      setEventID(queryString);
      let eventsList = await fetchEvents(queryString);
      setEvents(eventsList);
      return 'eventID set';
    }

    setEvent(queryString)
      .then((data) => console.log(data));
  }, [queryString, userRecord?.first_name, userRecord?.last_name]);

  return (
    <Page
      title={'Current Event'}
      {...props}
    >
      <ul style={{ paddingLeft: 0 }}>
        {events.map((event, index) => {
          return (
            <Paper
              variant={'outlined'}
              elevation={0}
              key={index}
            >
              {!eventID &&
                <>
                  <a href={`/events/current?eventID=${event.id}`} title={'event link'}> Select Event </a>
                  <div className={'event-view-box'}>
                    <div className="eventlist-event-card">
                      <div className={'row clearfix'}>
                        <span className={'label'}>Lesson Name:</span> {event.lesson_name}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>Points:</span> {event.points}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>Event Type:</span> {event.event_type}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>Location Name:</span> {event.location_name}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>City:</span> {event.city}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>Start Date:</span> {event.start_date}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>Start Time:</span> {event.start_time}
                      </div>
                      <div className={'row clearfix'}>
                        <span className={'label'}>End Time:</span> {event.end_time}
                      </div>
                      <div className={'row field-description clearfix'}>
                        <span className={'label'}>Description:</span>
                        <div className={'description-value'}>{event.description}</div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {eventID &&
                <>
                  <form>
                    <div id={'profile'} className={'flexbox'}>
                      <div className={'row row1'}>
                        <div className={'input-item label-above first-name required'}>
                          <label>
                            First Name
                          </label>
                          <div className={'input'}>
                            <input type="text" name="first_name" value={event.first_name} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above middle-name'}>
                          <label>
                            Middle Name
                          </label>
                          <div className={'input'}>
                            <input type="text" name="middle_name" value={event.middle_name} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above last-name required'}>
                          <label>
                            Last Name
                          </label>
                          <div className={'input'}>
                            <input type="text" name="last_name" value={event.last_name} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row2'}>
                        <div className={'input-item label-above email required'}>
                          <label>
                            Email
                          </label>
                          <div className={'input'}>
                            <input type="text" name="email" value={event.email} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row3'}>
                        <div className={'input-item label-above event-type required'}>
                          <label>
                            What type of event is this?
                          </label>
                          <div className={'input'}>
                            <input type="text" name="event-type-select" value={event.event_type} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above openness required'}>
                          <label>
                            Is this event open to the public?
                          </label>
                          <div className={'input'}>
                            <input type="text" name="event-openness" value={event.event_openness} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row4'}>
                        <div className={'input-item label-above location-name required'}>
                          <label>
                            Name of Event Location
                          </label>
                          <div className={'input'}>
                            <input type="text" name="location_name" value={event.location_name} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above street-address required'}>
                          <label>
                            Street Address of Event
                          </label>
                          <div className={'input'}>
                            <input type="text" name="street_address" value={event.street_address} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above zipcode required'}>
                          <label>
                            Zipcode
                          </label>
                          <div className={'input'}>
                            <input type="text" name="zipcode" value={event.zipcode} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above county required'}>
                          <label>
                            County
                          </label>
                          <div className={'input'}>
                            <input type="text" name="county" value={event.county} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row6'}>
                        <div className={'input-item label-above start-date required'}>
                          <label>
                            Event Start Date
                          </label>
                          <div className={'input'}>
                            <input type="text" name="start_date" value={event.start_date} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above start-time required'}>
                          <label>
                            Event Start Time
                          </label>
                          <div className={'input'}>
                            <input type="text" name="start_time" value={event.start_time} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above end-time required'}>
                          <label>
                            Event End Time
                          </label>
                          <div className={'input'}>
                            <input type="text" name="end_time" value={event.end_time} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row8'}>
                        <div className={'input-item label-above points'}>
                          <label>
                            Points
                          </label>
                          <div className={'input'}>
                            <input type="text" name="points" value={event.points} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row9'}>
                        <div className={'input-item label-above description'}>
                          <label>
                            Event Description
                          </label>
                          <div className={'input'}>
                            <textarea name={'description'} value={event.description} readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row10'}>
                        <div className={'input-item label-above curriculum-name'}>
                          <label>
                            Name of the Curriculum
                          </label>
                          <div className={'input'}>
                            <input type="text" name="curriculum_name" value={event.curriculum_name}
                                   readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above lesson-name'}>
                          <label>
                            Name of the Lesson
                          </label>
                          <div className={'input'}>
                            <input type="text" name="lesson_name" value={event.lesson_name} readOnly={true}/>
                          </div>
                        </div>
                        <div className={'input-item label-above pears_program_id'}>
                          <label>
                            PEARS Program Activity ID
                          </label>
                          <div className={'input'}>
                            <input type="text" name="pears_program_id" value={event.pears_program_id}
                                   readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row11'}>
                        <div className={'input-item label-above lesson-name'}>
                          <label>
                            Name of Booth Topic
                          </label>
                          <div className={'input'}>
                            <input type="text" name="booth_topic_name" value={event.booth_topic_name}
                                   readOnly={true}/>
                          </div>
                        </div>
                      </div>
                      <div className={'row row10'}>
                        <div className={'input-item label-above qr-code'}>
                          <div>
                            <a href={event.qr_code} alt={'qr_code download link'}>Download QR Code Image</a>
                          </div>
                          <div className={'input'}>
                            <a href={event.qr_code} download>
                              <img src={event.qr_code} alt={'qrcode url'}/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              }
            </Paper>
            // <li key={index}>{JSON.stringify(item)} </li>
          );
        })}
      </ul>
    </Page>
  );
};

export default OldCurrentEvent;
