/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCurriculum = /* GraphQL */ `
  mutation CreateCurriculum(
    $input: CreateCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    createCurriculum(input: $input, condition: $condition) {
      id
      curriculum_name
      additional {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessons {
        items {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCurriculum = /* GraphQL */ `
  mutation UpdateCurriculum(
    $input: UpdateCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    updateCurriculum(input: $input, condition: $condition) {
      id
      curriculum_name
      additional {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessons {
        items {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCurriculum = /* GraphQL */ `
  mutation DeleteCurriculum(
    $input: DeleteCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    deleteCurriculum(input: $input, condition: $condition) {
      id
      curriculum_name
      additional {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessons {
        items {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCurriculumLesson = /* GraphQL */ `
  mutation CreateCurriculumLesson(
    $input: CreateCurriculumLessonInput!
    $condition: ModelCurriculumLessonConditionInput
  ) {
    createCurriculumLesson(input: $input, condition: $condition) {
      id
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      lesson_name
      booth_topic
      snap_ed
      efnep
      cphp
      points
      description
      display_order
      audience
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCurriculumLesson = /* GraphQL */ `
  mutation UpdateCurriculumLesson(
    $input: UpdateCurriculumLessonInput!
    $condition: ModelCurriculumLessonConditionInput
  ) {
    updateCurriculumLesson(input: $input, condition: $condition) {
      id
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      lesson_name
      booth_topic
      snap_ed
      efnep
      cphp
      points
      description
      display_order
      audience
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCurriculumLesson = /* GraphQL */ `
  mutation DeleteCurriculumLesson(
    $input: DeleteCurriculumLessonInput!
    $condition: ModelCurriculumLessonConditionInput
  ) {
    deleteCurriculumLesson(input: $input, condition: $condition) {
      id
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      lesson_name
      booth_topic
      snap_ed
      efnep
      cphp
      points
      description
      display_order
      audience
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCurriculumAdditional = /* GraphQL */ `
  mutation CreateCurriculumAdditional(
    $input: CreateCurriculumAdditionalInput!
    $condition: ModelCurriculumAdditionalConditionInput
  ) {
    createCurriculumAdditional(input: $input, condition: $condition) {
      id
      additional_name
      snap_ed
      efnep
      cphp
      points
      description
      audience
      display_order
      curricula {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCurriculumAdditional = /* GraphQL */ `
  mutation UpdateCurriculumAdditional(
    $input: UpdateCurriculumAdditionalInput!
    $condition: ModelCurriculumAdditionalConditionInput
  ) {
    updateCurriculumAdditional(input: $input, condition: $condition) {
      id
      additional_name
      snap_ed
      efnep
      cphp
      points
      description
      audience
      display_order
      curricula {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCurriculumAdditional = /* GraphQL */ `
  mutation DeleteCurriculumAdditional(
    $input: DeleteCurriculumAdditionalInput!
    $condition: ModelCurriculumAdditionalConditionInput
  ) {
    deleteCurriculumAdditional(input: $input, condition: $condition) {
      id
      additional_name
      snap_ed
      efnep
      cphp
      points
      description
      audience
      display_order
      curricula {
        items {
          id
          curriculumID
          curriculumAdditionalID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          curriculumAdditional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            additional {
              nextToken
              __typename
            }
            lessons {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            curricula {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              cancel_reason
              canceled_by
              created_by
              updated_by
              canceledAt
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      title
      description
      points
      activity_code
      users {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      event_name
      event_type
      event_partner
      internal_only
      program_areas
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      additionalID
      additional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      participant_needs
      participant_needs_other
      main_office
      special_project
      points
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_by
      updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      event_name
      event_type
      event_partner
      internal_only
      program_areas
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      additionalID
      additional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      participant_needs
      participant_needs_other
      main_office
      special_project
      points
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_by
      updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      event_name
      event_type
      event_partner
      internal_only
      program_areas
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      additionalID
      additional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      participant_needs
      participant_needs_other
      main_office
      special_project
      points
      sessions {
        items {
          id
          status
          eventID
          event {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_by
      updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      status
      eventID
      event {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          curricula {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      status
      eventID
      event {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          curricula {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      status
      eventID
      event {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          curricula {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          events {
            items {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        sessions {
          items {
            id
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            cancel_reason
            canceled_by
            created_by
            updated_by
            canceledAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      is_sso
      points {
        items {
          id
          points
          type
          userID
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      is_sso
      points {
        items {
          id
          points
          type
          userID
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      is_sso
      points {
        items {
          id
          points
          type
          userID
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            users {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            is_sso
            points {
              nextToken
              __typename
            }
            activities {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserScore = /* GraphQL */ `
  mutation CreateUserScore(
    $input: CreateUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    createUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserScore = /* GraphQL */ `
  mutation UpdateUserScore(
    $input: UpdateUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    updateUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserScore = /* GraphQL */ `
  mutation DeleteUserScore(
    $input: DeleteUserScoreInput!
    $condition: ModelUserScoreConditionInput
  ) {
    deleteUserScore(input: $input, condition: $condition) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCurriculumAdditionalRelation = /* GraphQL */ `
  mutation CreateCurriculumAdditionalRelation(
    $input: CreateCurriculumAdditionalRelationInput!
    $condition: ModelCurriculumAdditionalRelationConditionInput
  ) {
    createCurriculumAdditionalRelation(input: $input, condition: $condition) {
      id
      curriculumID
      curriculumAdditionalID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      curriculumAdditional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCurriculumAdditionalRelation = /* GraphQL */ `
  mutation UpdateCurriculumAdditionalRelation(
    $input: UpdateCurriculumAdditionalRelationInput!
    $condition: ModelCurriculumAdditionalRelationConditionInput
  ) {
    updateCurriculumAdditionalRelation(input: $input, condition: $condition) {
      id
      curriculumID
      curriculumAdditionalID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      curriculumAdditional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCurriculumAdditionalRelation = /* GraphQL */ `
  mutation DeleteCurriculumAdditionalRelation(
    $input: DeleteCurriculumAdditionalRelationInput!
    $condition: ModelCurriculumAdditionalRelationConditionInput
  ) {
    deleteCurriculumAdditionalRelation(input: $input, condition: $condition) {
      id
      curriculumID
      curriculumAdditionalID
      curriculum {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        lessons {
          items {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            sessions {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      curriculumAdditional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            event_name
            event_type
            event_partner
            internal_only
            program_areas
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            additionalID
            additional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
              owner
              __typename
            }
            participant_needs
            participant_needs_other
            main_office
            special_project
            points
            sessions {
              nextToken
              __typename
            }
            created_by
            updated_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserActivities = /* GraphQL */ `
  mutation CreateUserActivities(
    $input: CreateUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    createUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        users {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserActivities = /* GraphQL */ `
  mutation UpdateUserActivities(
    $input: UpdateUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    updateUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        users {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserActivities = /* GraphQL */ `
  mutation DeleteUserActivities(
    $input: DeleteUserActivitiesInput!
    $condition: ModelUserActivitiesConditionInput
  ) {
    deleteUserActivities(input: $input, condition: $condition) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        users {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          items {
            id
            points
            type
            userID
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            activity {
              id
              title
              description
              points
              activity_code
              createdAt
              updatedAt
              owner
              __typename
            }
            user {
              id
              userpool_user_id
              email
              phone_number
              street_address
              city
              county
              race
              ethnicity
              gender
              age_range
              zip_code
              household_size
              allow_cellphone
              allow_notification
              allow_enews
              allow_text_program
              is_participating
              is_eligible
              first_name
              last_name
              full_name
              state
              birth_year
              is_sso
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
