import React, { useContext, useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import Grid from '@mui/material/Grid';
import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { Add } from '@mui/icons-material';
import { API, graphqlOperation } from 'aws-amplify';
import { listEvents } from '../../../../graphql/queries';
import { AbilityContext, Subjects, toGraphQuery } from '../../../../lib/permissions';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../../lib/contexts/userContext';
import { hydrateEventSessions } from '../eventFunctions';
import EventsTable from './EventsTable';

const ViewEvents = () => {
  const [events, setEvents] = useState(null);
  const [externalEvents, setExternalEvents] = useState([]);
  const [internalEvents, setInternalEvents] = useState([]);
  const [currentTab, setCurrentTab] = useState('external');

  const { authenticated, getStaffUsersWithCurrentUser } =
    useContext(UserContext);
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const canView = ability.can('view', Subjects.EVENTS);
    const filter = toGraphQuery(ability, 'view', Subjects.EVENTS);
    if (!canView && !filter) {
      return;
    }
    let active = true;

    Promise.all([
      getStaffUsersWithCurrentUser(),
      API.graphql({
        ...graphqlOperation(listEvents, { filter }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).catch((err) => {
        if (!active) {
          return;
        }
        console.log('[ViewEvents] error retrieving events:', err);
        return [];
      }),
    ]).then(([staff, eventResults]) => {
      if (!active) {
        return;
      }
      let eventData = eventResults?.data?.listEvents?.items || [];
      const getCreatedBy = (email) => {
        const staffUser = staff.find((user) => user.email === email);
        return staffUser?.name || email;
      };
      eventData = eventData.map((e) => {
        e.curriculum_name = e?.curriculum?.curriculum_name || 'Booth';
        e.created_by_names = getCreatedBy(e.created_by);
        return hydrateEventSessions(e);
      });
      setEvents(eventData);

      // Currently, we query all the events and split them into two
      // lists with a filter(). This is so we know whether both lists
      // have data in them, so we know which tabs to display.
      // When we implement pagination, we will need to query each
      // list of events separately.
      setExternalEvents(eventData.filter((e) => !e.internal_only));
      setInternalEvents(eventData.filter((e) => e.internal_only));
    });

    return () => {
      active = false;
    }
  }, [ability, authenticated, getStaffUsersWithCurrentUser]);

  // The tab list defaults to the 'community events' tab. If that one has no data,
  // show the 'training events' tab instead.
  let actualTab = currentTab;
  if (!externalEvents.length && internalEvents.length) {
    actualTab = 'internal';
  }

  // if (!events) {
  //   console.log('[ViewEvents][Loader]', events);
  // }
  return (
    <div id="events">
      <Grid container spacing={1}>
        {!events && (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        )}
        {!!events && (
          <>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  <Add sx={{ margin: "-5px", fontSize: '24px !important' }}/>
                }
                onClick={() => {
                  navigate('/events/create');
                }}
              >
                Create Event
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={actualTab}
                onChange={(event, newValue) => setCurrentTab(newValue)}
              >
                {externalEvents.length && (
                  <Tab label="Community Events" value="external" />
                )}
                {internalEvents.length && (
                  <Tab label="Training/Test Events" value="internal" />
                )}
              </Tabs>
              <div role="tabpanel" hidden={actualTab !== 'external'}>
                <EventsTable events={externalEvents} />
              </div>
              <div role="tabpanel" hidden={actualTab !== 'internal'}>
                <EventsTable events={internalEvents} />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ViewEvents;
