import React from 'react';
import {styled, TableCell, TableRow, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import {paletteMains} from '../../../lib/utils';

const UserEventItem = ({ event }) => {
  let eventStartDate = '';
  if (event?.event_start) {
    eventStartDate = event?.event_start.split('T')[0].replaceAll('-', '.');
  } else {
    eventStartDate = event?.start_date.replaceAll('-', '.');
  }

  const eventID = event?.id;

  const theme = useTheme();
  const palette = paletteMains(theme);

  const Plus = styled('span')({
    border: `1px solid ${palette.red}`,
    borderRadius: '100px',
    color: palette.red,
    display: 'inline-block',
    fontSize: theme.typography.fontSize,
    fontWeight: '600',
    padding: '0 4px',
  });

  return (
    <TableRow>
      <TableCell>{eventStartDate}</TableCell>
      <TableCell align="center">
        <Link to={`/events/details?eventID=${eventID}`}>
          <Plus> + </Plus>
        </Link>
      </TableCell>
      <TableCell align="center" sx={{ color: palette.green, fontWeight: 600 }}>{event.points}</TableCell>
      <TableCell>{event.event_type}</TableCell>
    </TableRow>
  );
};

export default UserEventItem;
