import React, { useContext } from 'react';
import Profile from './Profile';
import userContext from '../../../../lib/contexts/userContext';
import Page from '../../Page';
import { Pages } from '../../../../lib/constants';

const ProfilePage = () => {
  const { userState: { userRecord } } = useContext(userContext);

  return (
    <Page
      title="My Profile"
      pageId={Pages.PROFILE}
      navPhoto={{ image: '/images/profile/left-sidebar.png' }}
    >
      {userRecord &&
        <Profile />
      }
    </Page>
  );
};

export default ProfilePage;
