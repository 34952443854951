import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { PageContext } from '../../../../lib/contexts/pageContext';
import Grid from '@mui/material/Unstable_Grid2';
import { API, graphqlOperation } from 'aws-amplify';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { createFilter, scrollToAlert } from '../../../../lib/utils';
import { getCurricula, getPearsData } from '../eventFunctions';
import { AbilityContext, Subjects, toGraphQuery } from '../../../../lib/permissions';
import UserContext from '../../../../lib/contexts/userContext';
import { listEvents } from '../../../../graphql/queries';
import { deleteSession, updateEvent } from '../../../../graphql/mutations';
import { useNavigate, useParams } from 'react-router-dom';
import EventForm from '../EventForm';
import { useForm } from 'react-hook-form';
import useModifiedModal from './useModifiedModal';

const EditEvent = ({ setTitle }) => {
  const [formData, setFormData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const valuesSetState = useState(null);

  const { setPageMessage, setPageErrorMessage, updateCrumbLabel } = useContext(PageContext);
  const { userState: { userRecord }, authenticated, getStaffUsersWithCurrentUser } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const { eventID } = useParams();

  const navigate = useNavigate();

  const modifiedModal = useModifiedModal();

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      point: null,
      event_type: null,
      additional: null,
      curriculum: null,
      participant_needs: null,
      program_areas: null,
      created_by: null,
      event_name: null,
      event_partner: null,
      main_office: null,
      special_project: null,
      internal_only: null,
      description: 'Event description coming in a future phase.',
    }
  });

  const fetchEvent = useCallback(async (eventID, filterRules = {}) => {
    try {
      // Filter the event based on user permissions (if they can't view it, it won't return)
      const filter = createFilter(eventID, filterRules);
      const result = await API.graphql({
        ...graphqlOperation(listEvents, { filter }),
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      const [eventData] = result.data.listEvents.items;
      return eventData;
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  /** Pull curricula and staff users on load **/
  useEffect(() => {
    if (!eventID || !authenticated) {
      return;
    }
    // Try to get the query filter first in case the userRole hasn't been set and permissions haven't been updated
    const filter = toGraphQuery(ability, 'edit', Subjects.EVENT);
    if (ability.cannot('edit', Subjects.EVENT) && !filter) {
      // Staff that can only edit their own events will NOT have the edit EVENT permission because it's conditional
      // Therefore there must be a non-null filter
      console.log('[EditEvent] no edit permission and no filter');
      return;
    }

    let active = true;

    Promise.all([
      fetchEvent(eventID, filter).then((eventData) => {
        if (!active) {
          return;
        }
        if (eventData?.id) {
          updateCrumbLabel(eventData.id, eventData.event_name);
        }
        return eventData;
      }),
      getCurricula(),
      getStaffUsersWithCurrentUser(),
      getPearsData(),
    ]).then(([eventData, curricula, staff, pears]) => {
      if (!active) {
        return;
      }
      if (eventData) {
        setFormData({
          event: eventData,
          curricula: curricula,
          staff,
          pears,
        });
      } else {
        setFormData(false);
        setTitle('Error Accessing Page');
      }
    });

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, eventID, fetchEvent, ability]);

  /**
   * Submit handler.
   *
   * @param values
   */
  const onSubmit = async (values) => {
    setPageErrorMessage(null);
    setPageMessage(null);

    let destroySessions = false;

    if (formData.event.sessions.items.length > 0) {
      // Determine if any destructive modifications were made
      const triggerKeys = ['program_areas', 'event_type', 'curriculum'];
      const modifiedFields = Object.keys(values).reduce((acc, key) => {
        if (!triggerKeys.includes(key)) {
          return acc;
        }
        if (key === 'curriculum') {
          if (values[key]?.id && values[key].id !== formData.event?.curriculumID) {
            acc.push(key);
          }
        } else if (JSON.stringify(values[key]) !== JSON.stringify(formData.event[key])) {
          acc.push(key);
        }
        return acc;
      }, []);

      if (modifiedFields.length > 0) {
        destroySessions = true;

        // Pop modal asking user to confirm deleting sessions
        const continueSave = await modifiedModal({ modifiedFields, values, event: formData.event });
        if (!continueSave) {
          setSubmitting(false);
          return;
        } else if (continueSave === -1) {
          navigate(`/events/${formData.event.id}`);
          return;
        } else {
          // save changes
        }
      }
    }
    setSubmitting(true);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'additional':
        case 'curriculum':
          if (value?.id) {
            acc[`${key}ID`] = value?.id || null;
          }
          break;
        // case 'description':
        //   acc[key] = value || 'Event description coming in a future phase.';
        //   break;
        case 'participant_needs':
        case 'participant_needs_other':
        case 'program_areas':
        case 'created_by':
        case 'event_name':
        case 'event_partner':
        case 'event_type':
        case 'main_office':
        case 'points':
        case 'special_project':
          acc[key] = value?.id || value;
          break;
        case 'internal_only':
          acc[key] = values[key] === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      id: formData.event.id,
      updated_by: userRecord.email,
      event_name: null,
      event_partner: null,
      main_office: null,
      special_project: null,
      internal_only: null,
      event_type: null,
      additionalID: null,
      curriculumID: null,
      participant_needs: null,
      program_areas: null,
    });

    if (ability.cannot('edit', Subjects.EVENT)) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to edit this event.');
      scrollToAlert();
      return false;
    }

    if (input.created_by !== userRecord.email && ability.cannot('assign', Subjects.EVENT)) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to assign events to other staff members.');
      scrollToAlert();
      return false;
    }

    try {
      if (destroySessions) {
        await Promise.all(formData.event.sessions.items.map(({ id }) => API.graphql({
          ...graphqlOperation(deleteSession, { input: { id } }),
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })));
      }
      await API.graphql({
        ...graphqlOperation(updateEvent, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      setSubmitting(false);
      navigate(`/events/${formData.event.id}`, { state: { message: 'Event updated' }});
    } catch (error) {
      console.log('Error updating event: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error updating event. Please try again.');
      scrollToAlert();
    }
  };

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        { formData === false && (
          <>You do not have access, or the event was not found.</>
        )}
        { formData === null && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
        { !!formData && (
          <EventForm
            form={form}
            event={formData.event}
            pearsData={formData.pears}
            staffUsers={formData.staff}
            availableCurriculum={formData.curricula}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditEvent;
