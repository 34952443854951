import React, {useContext, useState} from 'react';
import {Auth} from 'aws-amplify';

import {useNavigate, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui';
import Grid from '@mui/material/Unstable_Grid2';
import {Button, CircularProgress, IconButton, InputAdornment, Link} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import UserContext from '../../../lib/contexts/userContext';
import {PageContext} from '../../../lib/contexts/pageContext';

const LoginForm = () => {
  const navigate = useNavigate();
  const { setPageMessage, setPageErrorMessage } = useContext(PageContext);
  const { signIn, isAdmin, isSSO } = useContext(UserContext);

  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: 'onSubmit',
  });

  const handleLogin = ({ email, password }) => {
    setSubmitting(true);
    setPageErrorMessage('');
    signIn(email, password)
      .then(() => {
        const redirect = searchParams.get('redirect');
        if (redirect) {
          navigate(redirect);
        } else if (isAdmin()) {
          navigate('/member/profile');
        } else if (isSSO()) {
          navigate('/events');
        } else {
          navigate('/member/activities');
        }
      })
      .catch((signinErr) => {
        console.log('error signing in', signinErr);
        if (signinErr.code === 'UserNotConfirmedException') {
          Auth.resendSignUp(email)
            .then(() => {
              setSubmitting(false);
              setValue('email', '');
              setValue('password', '');
              setPageMessage('A verification email has been sent to your email address. Please check your email and follow the instructions to verify your account.');
            })
            .catch((resendErr) => {
              setSubmitting(false);
              console.log('resendSignup error', resendErr);
              setPageErrorMessage('Your account has not been verified. Please click the link sent in the verification email, or try signing up again.');
            });
        } else {
          setSubmitting(false);
          setPageErrorMessage(signinErr.message);
        }
      });
    return false;
  };

  return (
    <FormContainer onSuccess={handleSubmit(handleLogin)} FormProps={{ className: 'mui' }}>
      <Grid container spacing={3}>
        {submitting && (
          <Grid xs={12} display="flex" justifyContent="center">
            <CircularProgress/>
          </Grid>
        )}
        {!submitting && (
          <>
            <Grid xs={12}>
              <TextFieldElement
                id="field-email"
                name="email"
                label={'Email'}
                variant={'standard'}
                control={control}
                autoComplete="email"
                required
                fullWidth
                placeholder="Enter your email here"
              />
            </Grid>
            <Grid xs={12}>
              <TextFieldElement
                id="field-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={'Password'}
                variant={'standard'}
                control={control}
                placeholder="Enter your password here"
                autoComplete="current-password"
                required
                fullWidth
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
          </>
        )}
        <Grid xs={12}>
          <Button
            id="button-signin"
            variant="contained"
            size="small"
            type="submit"
            disabled={submitting}
            fullWidth
            sx={{ marginTop: '20px' }}
          >
            Sign In
          </Button>
        </Grid>
        <Grid xs={12} display="flex" justifyContent={'center'}>
          <Link
            id="button-forgot"
            component={'button'}
            variant="body1"
            color="secondary"
            underline="none"
            sx={{ marginTop: '10px' }}
            onClick={() => {
              navigate('/login/password');
            }}
          >
            Forgot your password?
          </Link>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default LoginForm;
