import React from 'react';
import { List, ListItem } from '@mui/material';
import { useConfirm } from '../../../common/hooks/useConfirm';
import { programAreas } from '../../../../lib/constants';

const useModifiedModal = () => {
  const confirm = useConfirm();

  const renderDescription = ({ modifiedFields, values, event }) => {
    const getProgramArea = (id) => {
      const area = programAreas.find((pa) => pa.id === id);
      if (area) {
        return area.label;
      }
      return id;
    }

    const getChangeText = (field) => {
      switch (field) {
        case 'curriculum':
          return `Changed Primary Curriculum from ${event?.curriculum?.curriculum_name || 'Booth'} to ${values?.curriculum?.label || 'Booth'}`;
        case 'program_areas':
          return `Changed Program Areas from ${event.program_areas.map(getProgramArea).join(', ')} to ${values.program_areas.map(getProgramArea).join(', ')}`;
        case 'event_type':
          return `Changed Event Type from ${event.event_type} to ${values.event_type}`;
        default:
          return field;
      }
    };
    return (
      <>
        You made the following changes to this event that invalidate its existing sessions:
        <List sx={{ listStyleType: 'disc', marginLeft: '24px', paddingTop: 0, marginTop: 0 }}>
          { modifiedFields.map((field, i) => (
            <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }} disableGutters key={`field-${i}`}>
              { getChangeText(field) }
            </ListItem>
          ))}
        </List>
        <p>
          To save your changes, click <b>Save My Changes.
          WARNING: If you choose the Save My Changes option, all existing sessions for this event will be deleted.
          This cannot be undone.</b>
        </p>
        <p>
          To exit this page without saving your changes and go back to the Events Details page, click <b>Exit Without Saving.</b>
        </p>
        <p>
          If you want to continue editing this event, click <b>Keep Editing.</b>
        </p>
      </>
    );
  };

  return (props) => confirm({
    title: 'Are you sure you want to save your changes?',
    content: renderDescription(props),
    confirmationButtonProps: {
      variant: 'contained',
    },
    confirmationText: 'SAVE MY CHANGES',
    cancellationText: 'KEEP EDITING',
    valueButtons: [{
      text: 'EXIT WITHOUT SAVING',
      value: -1,
      color: 'secondary',
    }]
  })
    .then((result) => {
      console.log('continue result', result);
      return result || true;
    })
    .catch(() => false);
};

export default useModifiedModal;
