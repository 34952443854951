import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {QrReader} from 'react-qr-reader';
// import {API, graphqlOperation} from 'aws-amplify';
// import { getUser } from '../../../queries';
// import { createUserEvents } from '../../../mutations';
import UserContext from '../../../lib/contexts/userContext';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui';
import {useForm} from 'react-hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import {Button, Typography} from '@mui/material';
import {Box, Stack} from '@mui/system';
import {useNavigate} from 'react-router-dom';
import {PageContext} from '../../../lib/contexts/pageContext';

// const getSessionByShortcode = (code) => {
//   /** To be implemented with Sessions... **/
//   const variables = {
//     filter: {
//       short_code: {
//         eq: code
//       },
//     }
//   };
//   return API.graphql(graphqlOperation(listSessions, variables));
//   return null;
// };

const CheckIn = ({ onSuccess, onError }) => {
  const { isLoggedIn, userState: { userRecord } } = useContext(UserContext);
  const { setPageMessage, setPageErrorMessage } = useContext(PageContext);

  // showScanner: Hide or Show the QR reader block
  const [showScanner, setShowScanner] = useState(false);
  // scanning: create the QrReader element so that it captures data from the camera
  const [scanning, setScanning] = useState(false);
  // readScans: whether to process the incoming data
  const readScans = useRef(false);
  // submitting: whether the event code is being processed for check in
  const [submitting, setSubmitting] = useState(false);

  const scannedCode = useRef(null);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: userRecord?.email
    }
  });

  useEffect(() => {
    if (userRecord) {
      setValue('email', userRecord.email);
    }
  }, [setValue, userRecord]);

  const checkInWithShortcode = useCallback(async (eventShortCode) => {
    return onError('Not yet implemented. To be implemented with Sessions...');
    // setPageMessage(null);
    // setPageErrorMessage(null);
    // let userID;
    // if (isLoggedIn()) {
    //   userID = userRecord.id;
    // } else {
    //   // TODO: get user_id for non authenticated users
    //   onError('A user is not logged in.');
    //   return;
    // }
    // const eventCodeUpperCase = eventShortCode.toUpperCase();
    // // Check for an event with the given code
    // const event = await getSessionByShortcode(eventCodeUpperCase);
    // console.log(event);
    // if (event.data.eventByShortCode.items.length === 1) {
    //   const eventID = event.data.eventByShortCode.items[0].id;
    //   // Check if the user has already checked in to this event
    //   const getUserResult = await API.graphql(graphqlOperation(getUser, { id: userID }));
    //   const checkedInEvents = getUserResult.data.getUser.events_attended.items;
    //   const thisEvent = checkedInEvents.filter(userEvent => {
    //     return userEvent.eventID === eventID;
    //   });
    //
    //   if (thisEvent.length > 0) {
    //     onError('You are already checked in to this event.');
    //   } else {
    //     // Create the check-in record
    //     const createUserEventResponse = await API.graphql(graphqlOperation(createUserEvents, {
    //       input: {
    //         eventID: eventID,
    //         userID: userID
    //       }
    //     }));
    //
    //     if (createUserEventResponse.data.createUserEvents) {
    //       onSuccess();
    //       setValue('event_code', '');
    //     }
    //   }
    // } else {
    //   onError('Event not found. Please check the code and try again.');
    // }
  }, [onError]);

  const onSubmit = (data) => {
    setSubmitting(true);
    if (data.event_code) {
      checkInWithShortcode(data.event_code)
        .then(() => {
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          console.log('Error: ', error);
          onError('There was an error. Please try again.');
        });
    }
  };

  const handleErrorWebCam = (error) => {
    console.log('error scanning: ', error);
  };

  const handleScanWebcam = useCallback(async (result, error) => {
    if (submitting || !scanning || !readScans.current) return;
    if (!!result) {
      scannedCode.current = result?.text;
      setSubmitting(true);
      setShowScanner(false);
      readScans.current = false;
      await checkInWithShortcode(scannedCode.current);
      setSubmitting(false);
      setScanning(false);
    }

    if (!!error && JSON.stringify(error) !== JSON.stringify({})) {
      console.info(error);
    }
  }, [checkInWithShortcode, scanning, submitting]);

  const handleCancelScan = () => {
    setScanning(false);
    setShowScanner(false);
    readScans.current = false;
  };

  const handleScan = () => {
    setScanning(true);
    readScans.current = true;
    setShowScanner(true);
    setPageMessage(null);
  };

  const signupLoginButtons = () => (
    <Stack spacing={4}>
      <Box>
        <Typography variant="h6" fontWeight={600}>
          Ready to check in to an event?
        </Typography>
        <Typography>
          You must be logged in to your myEat.Move.Save. rewards account to check in to an event.
        </Typography>
      </Box>
      <Box>
        <Button
          id="button-login"
          type={'button'}
          variant="contained"
          size="small"
          onClick={() => navigate('/login?redirect=/checkin')}
        >
          Log In
        </Button>
      </Box>
      <Box>
        <Typography variant="h6" fontWeight={600}>
          Not a myEat.Move.Save. rewards member?
        </Typography>
        <Typography>
          Create your account today to earn points for today's event!
        </Typography>
      </Box>
      <Box>
        <Button
          id="button-signup"
          type={'button'}
          variant="contained"
          size="small"
          onClick={() => navigate('/signup')}
        >
          Sign Up
        </Button>
      </Box>
    </Stack>
  );

  const bodyContent = isLoggedIn() ? (
    <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{ className: 'mui' }} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography m={'16px 0'}>
            Get the event code from a staff member and enter it below, or scan the event QR code.
          </Typography>
        </Grid>
        <Stack component={Grid} spacing={4} xs={12} sm={6} useFlexGap>
          <TextFieldElement
            id="field-email"
            name="email"
            type={'text'}
            label={'Email Address'}
            variant={'standard'}
            control={control}
            fullWidth
            required
            disabled={isLoggedIn()}
            readOnly={isLoggedIn()}
          />
          <TextFieldElement
            id="field-event_code"
            name="event_code"
            type={'text'}
            label={'Event Code'}
            variant={'standard'}
            control={control}
            fullWidth
            required
          />

          {!scanning && (
            <Stack spacing={2} mt={'24px'}>
              <Box>
                <Button
                  id="button-submit"
                  disabled={submitting}
                  type={'submit'}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setPageMessage(null);
                    setPageErrorMessage(null);
                  }}
                >
                  Submit
                </Button>
              </Box>
              <Typography fontWeight={600}>
                - OR -
              </Typography>
              <Box>
                <Button
                  id="button-scan"
                  disabled={submitting}
                  type={'button'}
                  variant="contained"
                  size="small"
                  onClick={handleScan}
                >
                  Scan QR Code
                </Button>
              </Box>
            </Stack>
          )}
          {scanning && (
            <Box>
              <Button
                id="button-cancel_scan"
                disabled={submitting}
                type={'button'}
                variant="contained"
                size="small"
                mt={'10px'}
                onClick={handleCancelScan}
              >
                Cancel Scan
              </Button>
            </Box>
          )}

          {scanning && (
            <Box sx={{ marginTop: '20px', display: showScanner ? 'block' : 'none' }}>
              <>
                <QrReader
                  delay={500}
                  containerStyle={{ width: '100%', height: 'auto' }}
                  videoContainerStyle={{
                    width: '100%',
                    paddingTop: '0',
                    overflow: 'none',
                    position: 'relative',
                    paddingBottom: '75%', /* 4:3 */
                    height: '0',
                  }}
                  videoStyle={{
                    overflow: 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'
                  }}
                  constraints={{ facingMode: 'environment' }}
                  onError={handleErrorWebCam}
                  onResult={handleScanWebcam}
                />
              </>
            </Box>
          )}
        </Stack>
      </Grid>
    </FormContainer>
  ) : signupLoginButtons();

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10} sm={10} md={8}>
        { bodyContent }
      </Grid>
    </Grid>
  )
};

export default CheckIn;
