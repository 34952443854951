import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom';
import { UserProvider } from './lib/contexts/userContext';
import { PageProvider } from './lib/contexts/pageContext';
import App from './App'
import ScrollToTop from './components/common/ScrollToTop';
import './css/index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <PageProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </PageProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
