import { useContext, useEffect } from 'react';
import { scrollToAlert } from '../../../lib/utils';
import { PageContext } from '../../../lib/contexts/pageContext';

function useErrorHandling({
  errors,
  isSubmitting,
  wasSubmitting,
  setWasSubmitting,
  scrolling = true,
  modalContext = null,
}) {
  let { setPageMessage, setPageErrorMessage } = useContext(PageContext);
  if (modalContext) {
    // If the form is contained in a modal, then we need to use different message handlers, hence the override
    ({ setPageMessage, setPageErrorMessage } = modalContext);
  }

  /** ERROR HANDLING **/
  useEffect(() => {
    const errorValues = Object.values(errors);
    const [missingRequired, otherErrors] = errorValues.reduce((acc, error) => {
      if (error.type === 'required') {
        acc[0] = true;
      } else {
        if (!acc[1].includes(error.message)) {
          acc[1].push(error.message);
        }
      }
      return acc;
    }, [false, []]);

    // Here tracks if the form was submitted on the last render (meaning submit was clicked and validation is occurring)
    if (isSubmitting) {
      setWasSubmitting(true);
    } else if (wasSubmitting) {
      setWasSubmitting(false);
    }

    if (missingRequired || !!otherErrors.length) {
      // Only show the alert if the form was actually submitted via the button
      if (wasSubmitting) {
        const messages = [];
        if (missingRequired) {
          messages.push('Please fill out all required (*) fields.');
        }
        if (!!otherErrors.length) {
          messages.push(...otherErrors)
        }
        if (messages.length) {
          setPageMessage(null);
          setPageErrorMessage(messages);
        }
        if (scrolling) {
          scrollToAlert();
        }
      }
    } else {
      // setPageErrorMessage(null);
    }
  }, [errors, isSubmitting, scrolling, setPageErrorMessage, setPageMessage, setWasSubmitting, wasSubmitting]);
}

export default useErrorHandling;
