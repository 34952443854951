import React from 'react';
import {List, ListItem, Typography} from '@mui/material';
import {rewardLevels} from '../../../lib/constants';
import {Box, Stack} from '@mui/system';


const RewardLevels = () => {
  return (
    <div className={'reward-levels-container'}>
      <List>
        {rewardLevels.map((rewardLevel, rli) =>
          <ListItem key={`rl-${rli}`}>
            <Stack sx={{ width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={0}
                sx={{ '& .star': { width: '15%' }}}
                mt={'10px'}
              >
                {[...Array(rewardLevel.stars).keys()].map((i) =>
                  <span className={'star'} key={i}><img src="/images/rewards/YELLOW_STAR.svg" alt={'reward star icon'}/></span>
                )}
              </Box>
              <Typography variant="body2" sx={{ fontWeight: 600 }} align="center">
                { `${rewardLevel.points} points` }
              </Typography>
              <Typography variant="body2" align={'center'}>
                { rewardLevel.item }
              </Typography>
            </Stack>
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default RewardLevels;
