import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize, last, getArrayMsg } from '../utils';

function slugsToLabel(path) {
  const pathLabels = {
    home: 'Home',
    events: 'Events',
    'events.create': 'Create Event',
    'events.details': 'Event Details',
    member: 'Member',
    'member.profile': 'Profile',
    'member.activities': 'Activities',
    'member.password': 'Change Password',
  };
  return pathLabels[path.join('.')] || capitalize(last(path));
}

function getPathData(location) {
  const path = [];
  const pathName = location.pathname.replace(/^\/+|\/+$/g, '');
  const parts = !!pathName ? pathName.split('/') : [];

  // special case for INEP Create Event
  if (location.pathname.includes('INEP=')) {
    path.push('events', 'create');
  } else {
    parts.forEach((part) => path.push(part));
  }

  const sectionName = path.length ? path[0] : 'home';
  const pageName = path.length ? last(path) : 'home';
  const pageNameCap = capitalize(pageName);

  const crumbs = [];
  const trail = [];
  for (let i = 0; i < path.length; i++) {
    trail.push(path[i]);
    const label = slugsToLabel(trail);
    const url = `/${trail.join('/')}`;
    crumbs.push({
      label,
      url,
      last: i === path.length - 1,
    });
  }
  crumbs.unshift({
    label: 'Home',
    url: '/',
  });

  return {
    level: path.length ? path.length - 1 : 0,
    path,
    crumbs,
    sectionName,
    pageName,
    pageNameCap
  };
}

export const PageContext = createContext({ pageMessage: '', pageErrorMessage: '' });

export const PageProvider = ({ children }) => {
  const location = useLocation();
  const [pathData, setPathData] = useState(getPathData(location));
  const [crumbs, setCrumbs] = useState(pathData.crumbs);
  const [pageMessages, setMessages] = useState(null);
  const [pageErrorMessages, setErrorMessages] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [authorized, setAuthorized] = useState(null);

  const crumbsRef = useRef(pathData.crumbs);

  const setPageMessages = useCallback((msg) => {
    setMessages(getArrayMsg(msg));
  }, []);

  const setPageErrorMessages = useCallback((msg) => {
    setErrorMessages(getArrayMsg(msg));
  }, []);

  const appendMessages = useCallback((msg) => {
    if (!msg) {
      setPageMessages(null);
    } else if (Array.isArray(msg)) {
      if (msg.length > 0) {
        pageMessages.push(...msg);
        setPageMessages(pageMessages);
      } else {
        setPageMessages(null);
      }
    } else {
      pageMessages.push(msg);
      setPageMessages(pageErrorMessages);
    }
  }, [pageErrorMessages, pageMessages, setPageMessages]);

  const appendErrorMessages = useCallback((msg) => {
    if (!msg) {
      setPageErrorMessages(null);
    } else if (Array.isArray(msg)) {
      if (msg.length > 0) {
        pageErrorMessages.push(...msg);
        setPageErrorMessages(pageMessages);
      } else {
        setPageErrorMessages(null);
      }
    } else {
      pageErrorMessages.push(msg);
      setPageErrorMessages(pageErrorMessages);
    }
  }, [pageErrorMessages, pageMessages, setPageErrorMessages]);

  /**
   * Update a label (usually an ID to make it user readable).
   *
   * @param label
   */
  const updateCrumbLabel = useCallback((oldLabel, newLabel) => {
    const crumbsCurr = crumbsRef.current;
    const index = crumbsCurr.findIndex((c) => c.label.toLowerCase().trim() === oldLabel.toLowerCase().trim());
    if (index > -1) {
      crumbsCurr[index].label = newLabel;
      setCrumbs([ ...crumbsCurr ]);
    }
  }, []);

  // When the authenticated user changes, load a new user record and update session state variables
  useEffect(() => {
    const updatedPathData = getPathData(location);
    setPathData(updatedPathData);
    crumbsRef.current = updatedPathData.crumbs;
    setCrumbs(updatedPathData.crumbs);
  }, [location]);

  return (
    <PageContext.Provider value={{
      pathData,
      crumbs,
      pageMessages,
      setPageMessages,
      setPageMessage: setPageMessages,
      pageErrorMessages,
      setPageErrorMessages,
      setPageErrorMessage: setPageErrorMessages,
      appendMessages,
      appendErrorMessages,
      updateCrumbLabel,
      pageLoading,
      setPageLoading,
      authorized,
      setAuthorized,
    }}>
      {children}
    </PageContext.Provider>
  );
};
