import React, {useContext} from 'react';
import NavDrawer from './components/common/Layout/NavDrawer';
import PageRoutes from './components/Pages/PageRoutes';
import Footer from './components/common/Layout/Footer';
import Header from './components/common/Layout/Header';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import colors from './sass/src_mui/_init/_variables.scss';
// import { ConfirmProvider } from 'material-ui-confirm';
import { ConfirmProvider } from './components/common/hooks/useConfirm';
import {PageContext} from './lib/contexts/pageContext';

// AMPLIFY
import awsExports from './aws-exports.js';
import { Amplify } from '@aws-amplify/core';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth } from 'aws-amplify';
import { refreshHandler } from './lib/auth';
import storage from './lib/storage';

if (process.env?.REACT_APP_CLIENT_ID) {
  // In order for SSO to work properly and in tandem with basic user/pass authentication, we need to use the same
  // client ID. The web client does not work with SSO, so we must force Amplify to use client id.
  awsExports.aws_user_pools_web_client_id = process.env.REACT_APP_CLIENT_ID;
}

Amplify.configure(awsExports);

Auth.configure({
  storage,
  refreshHandlers: {
    developer: refreshHandler,
  }
});

const filledButtonStyles = (color) => {
  return {
    backgroundColor: color,
    border: `1px solid ${color}`,
    color: '#fff',
    '&:hover, &:focus': {
      color,
      background: '#fff',
      border: `1px solid ${color}`
    },
  };
};

const textButtonStyles = (color) => {
  return {
    color: color,
    '&:hover, &:focus': {
      background: `${color}25`,
    },
  };
};

const outlinedButtonStyles = (color) => {
  return {
    color: color,
    border: `1px solid ${color}40`,
    '&:hover, &:focus': {
      background: `${color}15`,
      border: `1px solid ${color}60`,
    },
  };
};

const App = () => {
  const { pathData: { sectionName, level } } = useContext(PageContext);

  const colorPalette = Object.keys(colors).reduce((acc, key) => {
    acc[key] = { main: colors[key] };
    return acc;
  }, {
    primary: { main: colors['il-industrial'] },
    secondary: { main: colors['il-blue'] },
    hover: { main: colors['il-altgeld'] },
    text: { primary: colors['il-storm'] },
    red: { main: colors['red'] },
    blue: { main: colors['blue'] },
    ilblue: { main: colors['il-blue'] },
    ilorange: { main: colors['il-orange'] },
    ilaltgeld: { main: colors['il-altgeld'] },
    ilalma: { main: colors['il-alma'] },
    ilstorm: { main: colors['il-storm'] },
    ilindustrial: { main: colors['il-industrial'] },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    }
  });

  let theme = createTheme({
    typography: {
      fontFamily: 'SourceSans',
      fontSize: 14,
      htmlFontSize: 16,
      h1: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h2: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h3: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h4: {
        fontFamily: 'Montserrat',
        fontSize: '32px',
        marginBottom: '21px',
        fontWeight: 700,
        color: colorPalette.ilblue.main,
      },
      h5: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h6: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: colorPalette.ilblue.main,
      },
      h7: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '18px',
      }
    },
    palette: colorPalette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        sd: 760,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            textDecorationColor: colorPalette.ilindustrial.main,
            '&:hover': {
              cursor: 'pointer',
              color: colorPalette.hover.main,
              transition: 'color 0.3s',
            }
          }
        },
        defaultProps: {
          underline: 'always',
        },
        variants: [
          {
            props: { variant: 'footer' },
            style: {
              fontFamily: 'SourceSans',
              color: '#fff',
              textDecorationColor: '#fff',
              '&:hover': {
                color: colorPalette.ilorange.main,
              }
            }
          }
        ]
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#000',
            fontWeight: 600,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:not(.MuiPickersToolbar-root)': {
              fontSize: '1rem',
              fontFamily: 'SourceSans',
              textTransform: 'none',
              borderRadius: '5px',
              padding: '0.25rem 1.3rem',
              boxShadow: 'unset',

              // Note: The official way to override these styles is to use the
              // 'variants' object, but that doesn't seem to support changing 'hover'
              // styles, so I had to fall back to using CSS selectors. - KD 2024-11-19

              '&.MuiButton-filled, &.MuiButton-contained': {
                color: '#fff',
                '&.MuiButton-colorPrimary': filledButtonStyles(colorPalette.ilblue.main),
                '&.MuiButton-colorSuccess': filledButtonStyles(colorPalette.success.main),
                '&.MuiButton-colorError': filledButtonStyles(colorPalette.error.main),
              },

              '&.MuiButton-text': {
                '&.MuiButton-colorPrimary': textButtonStyles(colorPalette.ilblue.main),
                '&.MuiButton-colorSuccess': textButtonStyles(colorPalette.success.main),
                '&.MuiButton-colorError': textButtonStyles(colorPalette.error.main),
              },

              '&.MuiButton-outlined': {
                '&.MuiButton-colorPrimary': outlinedButtonStyles(colorPalette.ilblue.main),
                '&.MuiButton-colorSuccess': outlinedButtonStyles(colorPalette.success.main),
                '&.MuiButton-colorError': outlinedButtonStyles(colorPalette.error.main),
              },

            },
          },
        },
        variants: [
          {
            props: { size: 'extraSmall' },
            style: { fontSize: '0.7rem', padding: '2px 1.5rem' }
          }
        ]
      },
      MuiListItemButton: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
      MuiDateTimePickerToolbar: {
        styleOverrides: {
          timeContainer: {
            alignSelf: 'end',
            '& .MuiPickersToolbarText-root.MuiTypography-h3': {
              lineHeight: 1.1,
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: '#fff',
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: colorPalette.ilstorm.main,
          }
        }
      }
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiList: {
        variants: [
          {
            props: { variant: 'nav' },
            style: {
              borderLeftColor: 'transparent',
              '& a': {
                padding: '4px 16px',
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '&:hover': {
                  '.MuiListItemText-root span': {
                    color: colorPalette.ilaltgeld.main,
                  }
                },
                '& .MuiListItemText-root span': {
                  color: colorPalette.ilblue.main,
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: theme.typography.pxToRem(16),
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(20),
                  },
                },
              },
            }
          }
        ],
      },
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavDrawer />
      <ConfirmProvider>
        <div id="layout-container" className={'section-' + sectionName + ' lvl-' + level} data-section-name={sectionName}> {/*used for the expanding mobile menu*/}
          <Header/>
          <main className={'bodywrapper'}>
            <PageRoutes />
          </main>
        </div>
        <Footer/>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default App;
