import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  useTheme
} from '@mui/material';
import { useController } from 'react-hook-form';
import { TextFieldElement, useFormError } from 'react-hook-form-mui';
import { forwardRef } from 'react';
import { useTransform } from './useTransform';
import { propertyExists } from './utils';

const CheckboxButtonGroup = forwardRef(function CheckboxButtonGroup(
  props,
  ref
) {
  const {
    helperText,
    options,
    label,
    name,
    parseError,
    required,
    labelKey = 'label',
    valueKey = 'id',
    returnObject,
    disabled,
    readOnly,
    row,
    control,
    checkboxColor,
    rules,
    labelProps,
    transform,
    fullWidth,
    textFieldRequired,
    defaultValue = [],
    ...rest
  } = props;

  const theme = useTheme();
  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;

  const {
    field,
    fieldState: { error, invalid }
  } = useController({
    name,
    rules: required ? { required: 'This field is required' } : rules,
    disabled,
    control,
    defaultValue: defaultValue
  });

  const { value: selectedOptions, onChange } = useTransform({
    value: field.value,
    onChange: field.onChange,
    transform: {
      input:
        typeof transform?.input === 'function'
          ? transform.input
          : value => {
            return Array.isArray(value) ? value : [];
          },
      output: transform?.output
    }
  });

  const handleChange = option => {
    const optionValue = propertyExists(option, valueKey)
      ? option[valueKey]
      : option;
    const existsAtIndex = selectedOptions.findIndex(selectedOption => {
      const selectedOptionValue = propertyExists(selectedOption, valueKey)
        ? selectedOption[valueKey]
        : selectedOption;
      return optionValue === selectedOptionValue;
    });

    const newValues = (existsAtIndex === -1
        ? [...selectedOptions, option]
        : selectedOptions.filter((_, index) => existsAtIndex !== index)
    ).map(selectedOption =>
      returnObject || !propertyExists(selectedOption, valueKey)
        ? selectedOption
        : selectedOption[valueKey]
    );
    onChange(newValues);
    if (typeof rest.onChange === 'function') {
      rest.onChange(newValues);
    }
  };

  const renderHelperText = error
    ? typeof customErrorFn === 'function'
      ? customErrorFn(error)
      : error.message
    : helperText;

  return (
    <FormControl error={invalid} required={required} ref={ref} fullWidth={!!fullWidth}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <FormGroup row={row}>
        {options.map(option => {
          const optionValue = propertyExists(option, valueKey)
            ? option[valueKey]
            : option;
          const optionLabel = propertyExists(option, labelKey)
            ? option[labelKey]
            : option;

          const isChecked = selectedOptions.some(selectedOption => {
            const selectedOptionValue = propertyExists(selectedOption, valueKey)
              ? selectedOption[valueKey]
              : selectedOption;
            return selectedOptionValue === optionValue;
          });

          const hasTextField = option?.text;

          return (
            <FormControlLabel
              {...labelProps}
              sx={ hasTextField ? {
                alignItems: 'flex-start',
                '& .MuiFormControlLabel-label': {
                  flexGrow: 1,
                  paddingTop: '9px',
                }
              } : {}}
              control={
                <Checkbox
                  sx={{
                    color: error ? theme.palette.error.main : undefined,
                  }}
                  color={checkboxColor}
                  value={optionValue}
                  checked={isChecked}
                  disabled={disabled || readOnly}
                  inputProps={{
                    disabled: (readOnly ? false : disabled),
                  }}
                  onChange={() => readOnly ? null : handleChange(option)}
                />
              }
              label={
                <>
                  <span>{optionLabel}</span>
                  { hasTextField && isChecked && (
                    <TextFieldElement
                      name={option.textId}
                      variant={'standard'}
                      control={control}
                      required={textFieldRequired}
                      fullWidth
                    />
                  )}
                </>
              }
              key={`${optionValue}`}
            />
          );
        })}
      </FormGroup>
      {renderHelperText && <FormHelperText>{renderHelperText}</FormHelperText>}
    </FormControl>
  );
});
CheckboxButtonGroup.displayName = 'CheckboxButtonGroup';
export default CheckboxButtonGroup;
