import MenuIcon from '@mui/icons-material/Menu';
import React, {useContext, useState} from 'react';
import {AppBar, Drawer, IconButton, Toolbar, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {paletteMains} from '../../../lib/utils';
import NavMenu from './NavMenu';
import {ClickAwayListener} from '@mui/base';
import {Box} from '@mui/system';
import UserContext from '../../../lib/contexts/userContext';

const NavDrawer = () => {
  const [open, setOpen] = useState(false);
  const { isLoggedIn } = useContext(UserContext);

  const theme = useTheme();
  const palette = paletteMains(theme);

  const appBarWidth = {
    width: '230px',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
  };
  const drawerWidth = {
    width: '230px',
    [theme.breakpoints.down('sm')]: { width: '260px' }
  };

  if (!isLoggedIn()) {
    return null;
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => open && setOpen(false)}
    >
      <Box>
        <AppBar position="fixed" elevation={0} sx={{
          backgroundColor: palette.ilorange,
          ...appBarWidth,
        }}>
          <Toolbar disableGutters variant="dense" sx={{ minHeight: 0 }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(!open)}
              sx={{
                marginLeft: '4px',
                padding: '8px',
                fontSize: theme.typography.pxToRem(20)
              }}
            >
              {!open && <MenuIcon sx={{ marginRight: '8px' }}/>}
              {open && <CloseIcon sx={{ marginRight: '8px' }}/>}
              MENU
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            ...drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              ...drawerWidth,
              top: '39px',
              borderLeftColor: palette.ilorange,
              borderTop: `1px solid ${palette.ilorange}`,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <NavMenu
            showHome
            onLinkClick={() => setOpen(false)}
          />
        </Drawer>
      </Box>
    </ClickAwayListener>
  );
};

export default NavDrawer;
