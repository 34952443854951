import { Pages } from '../../../lib/constants';
import SessionsUpcoming from './SessionsUpcoming';
import Page from '../Page';

const SessionsUpcomingPage = () => {
  return (
    <Page
      pageId={Pages.UPCOMING_SESSIONS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      requireAuth
    >
      <SessionsUpcoming />
    </Page>
  )
};

export default SessionsUpcomingPage;
