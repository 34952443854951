import React from 'react';
import {Link, styled, Typography, useTheme} from '@mui/material';
import {paletteMains} from '../../../lib/utils';
import {Stack} from '@mui/system';
import Grid from '@mui/material/Grid';
import {Link as RouterLink} from 'react-router-dom';


const TotalScore = ({ userScore }) => {
  let userScoreArray = [];

  const theme = useTheme();
  const palette = paletteMains(theme);

  const splitTotal = (pointsTotal) => {
    const pointsTotalString = String('0000' + pointsTotal).slice(-3);
    return [...pointsTotalString];
  };


  // const userScore = parseInt(classTotal) + parseInt(boothTotal)
  if (userScore > -1) {
    userScoreArray = splitTotal(userScore);
    userScoreArray.unshift('0');
  }

  const PointDigit = styled(Grid)(({ theme }) => ({
    backgroundImage: `linear-gradient(${palette['darkest-grey']}, ${palette['white']}, ${palette['darkest-grey']})`,
    float: 'left',
    marginRight: '5px',
    fontWeight: '600',
    padding: '5px 4px',
    border: `1px solid ${palette['dark-grey']}`,
    fontSize: '2.9rem', // 3.5rem originally
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.4rem',
    }
  }));

  return (
    <Stack>
      <Typography
        variant="h6"
        sx={{
          color: palette.blue,
          fontWeight: 'bold',
          marginBottom: '20px',
        }}
      >
        MY REWARDS
      </Typography>
      <Typography
        variant="h7"
        sx={{ color: palette.blue, fontWeight: 'bold' }}
      >
        TOTAL POINTS EARNED
      </Typography>
      <Typography variant="body2">
        Since joining the program
      </Typography>
      <Grid container sx={{ margin: '20px 0', [theme.breakpoints.only('xs')]: { justifyContent: 'center' } }}>
        {userScoreArray.map((digit, index) =>
          <PointDigit item xs={'auto'} key={index}>
            { digit }
          </PointDigit>
        )}
      </Grid>
      <Typography variant="body2">
        Each time your total points pass the next Star Level, you will get an email with instructions for claiming
        your reward.
      </Typography>

      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        <Link to={'/member/profile'} component={RouterLink}>
          Please make sure your email address and phone number are always up-to-date in your
          myEat.Move.Save. profile!
        </Link>
      </Typography>
    </Stack>
  );
};

export default TotalScore;
