import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { decodeJWT } from './utils';
import storage from './storage';

export const authData = {
  ClientId: process.env.REACT_APP_CLIENT_ID,
  WebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
  AuthDomain: process.env.REACT_APP_AUTH_DOMAIN,
  RedirectUriSignIn: process.env.REACT_APP_AUTH_REDIRECT,
  IdentityProvider: 'illinois.edu',
};

export const ssoUrl = [
  `${process.env.REACT_APP_AUTH_DOMAIN}/login?`,
  `response_type=code`,
  `&`,
  `client_id=${process.env.REACT_APP_CLIENT_ID}`,
  `&`,
  `redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT}`,
].join('');

export const refreshHandler = async () => {
  const usernameKey = 'CognitoIdentityServiceProvider.' + authData.ClientId + '.LastAuthUser';
  const username = storage.getItem(usernameKey);
  if (!username) {
    throw new Error('No username found in storage.');
  }
  const refreshKey = 'CognitoIdentityServiceProvider.' + authData.ClientId + '.' + username + '.refreshToken';
  const refreshToken = storage.getItem(refreshKey);
  if (!refreshToken) {
    throw new Error('No refresh token found in storage.');
  }
  return axios.post(authData.AuthDomain + '/oauth2/token', {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: authData.ClientId,
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
  }).then((data) => data.data).catch((error) => {
    console.log('token error', error);
    return null;
  });
};

/**
 * Converts text based tokens into a CognitoUserSession (usually for use in creating a CognitoUser).
 *
 * @param accessToken The access token to be injected. Access tokens grant access to resources.
 * @param idToken The id token to be injected. ID tokens contain claims about identity.
 * @param refreshToken The refresh token to be injected. Refresh tokens can obtain new access
 * and id tokens for a long period of time (usually up to a year).
 */
const createCognitoSession = (accessToken, idToken, refreshToken) => new CognitoUserSession({
  IdToken: new CognitoIdToken({
    IdToken: idToken
  }),
  RefreshToken: new CognitoRefreshToken({
    RefreshToken: refreshToken
  }),
  AccessToken: new CognitoAccessToken({
    AccessToken: accessToken
  })
});

/**
 * Injects an access token, id token, and refresh token into AWS Amplify for identity and access
 * management. Cognito will store these tokens in memory, and they will persist upon requesting
 * additional pages from the same domain.
 *
 * Calling this method should have the same effect as signing in with Auth.signIn(). When an
 * id or access token expires, Cognito will automatically retrieve new ones using the refresh
 * token passed.
 *
 * Note: Token injection is not "officially" supported by Amplify. The only forms of sign-in
 * Amplify supports are username & password or federated sign-in.
 *
 * @param accessToken The access token to be injected. Access tokens grant access to resources.
 * @param idToken The id token to be injected. ID tokens contain claims about identity.
 * @param refreshToken The refresh token to be injected. Refresh tokens can obtain new access
 * and id tokens for a long period of time (usually up to a year).
 */
const injectCognitoTokens = async (accessToken, idToken, refreshToken) => {
  let session;
  // check expiration first
  const accessTokenData = decodeJWT(accessToken);
  if (accessTokenData.exp < Math.floor(Date.now() / 1000)) {
    // console.log('[auth] token expired, attempting refresh...');
    const refreshResult = await refreshHandler();
    // console.log('[auth] refresh token result', refreshResult);
    session = createCognitoSession(refreshResult.access_token, refreshResult.id_token, refreshToken);
  } else {
    session = createCognitoSession(accessToken, idToken, refreshToken);
  }

  // console.log('[auth] setting credentials session...');
  await Auth.Credentials.set(session, 'session').catch((error) => {
    console.log('cognito credentials error', error, refreshToken);
  });

  return session;
};

/**
 * Injects the provided tokens and creates a CognitoUser which also gets set as the current user for Amplify services.
 * This is necessary for API authentication, and token refresh when attempting to access the GraphQL API with an
 * expired token.
 *
 * @param accessToken The access token to be injected. Access tokens grant access to resources.
 * @param idToken The id token to be injected. ID tokens contain claims about identity.
 * @param refreshToken The refresh token to be injected. Refresh tokens can obtain new access
 * and id tokens for a long period of time (usually up to a year).
 */
export const createCognitoUser = async (accessToken, idToken, refreshToken) => {
  const session = await injectCognitoTokens(accessToken, idToken, refreshToken);

  // The function createCognitoUser is private in Amplify. So, we need to cast it
  // in order to call it.
  // console.log('[auth] creating Cognito user...');
  const currentUser = Auth.createCognitoUser(
    session.getIdToken().decodePayload()['cognito:username']
  );
  // console.log('[auth] currentUser', currentUser);
  // This calls cacheTokens() in Cognito SDK. Assigns the tokens to the local identity.
  // console.log('[auth] setting signInUserSession...');
  currentUser.setSignInUserSession(session);
  return currentUser;
};

export const getStoredTokens = () => {
  const keyPrefix = `CognitoIdentityServiceProvider.${authData.ClientId}`;
  const lastAuthUser = storage.getItem(`${keyPrefix}.LastAuthUser`);
  if (!lastAuthUser) {
    return null;
  }

  const accessToken = storage.getItem(`${keyPrefix}.${lastAuthUser}.accessToken`);
  const idToken = storage.getItem(`${keyPrefix}.${lastAuthUser}.idToken`);
  const refreshToken = storage.getItem(`${keyPrefix}.${lastAuthUser}.refreshToken`);
  return { accessToken, idToken, refreshToken };
};

/**
 * Helper function for creating a CognitoUser from storage.
 */
export const createCognitoUserFromSession = async () => {
  const tokens = getStoredTokens();
  if (!tokens) {
    throw new Error('No authenticated user in storage.');
  }

  const { accessToken, idToken, refreshToken } = tokens;

  if (!accessToken || !idToken || !refreshToken) {
    throw new Error('Stored tokens invalid.');
  }
  const createUser = async () => {
    await createCognitoUser(
      accessToken,
      idToken,
      refreshToken,
    );
    return Auth.currentAuthenticatedUser({ bypassCache: true }).catch(() => null);
  }
  let session = await createUser();
  if (!session) {
    session = await createUser();
  }
  return session;
};

/**
 * Retrieves session (3 tokens) from Cognito using a provided code. Then creates the Cognito user which registers
 * a Cognito session for the user. This happens when a code is received from the SSO auth flow.
 *
 * @param code
 */
export const getTokenByCode = async (code) => {
  try {
    const data = await axios.post(authData.AuthDomain + '/oauth2/token', {
      grant_type: 'authorization_code',
      code,
      client_id: authData.ClientId,
      redirect_uri: authData.RedirectUriSignIn,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      }
    }).then((data) => data.data).catch((error) => {
      console.log('token error', error);
      return null;
    });
    // console.log('[getTokenByCode]', data);
    if (!data?.access_token || !data?.id_token) {
      return null;
    }

    // const idTokenData = decodeJWT(data.id_token);
    // const accessTokenData = decodeJWT(data.access_token);

    return createCognitoUser(data.access_token, data.id_token, data.refresh_token);
  } catch (err) {
    console.error(err);
    return null;
  }
};
