import { Pages } from '../../../lib/constants';
import SessionsPast from './SessionsPast';
import Page from '../Page';

const SessionsPastPage = () => {
  return (
    <Page
      pageId={Pages.PAST_SESSIONS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      requireAuth
    >
      <SessionsPast />
    </Page>
  )
};

export default SessionsPastPage;
