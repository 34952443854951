import React, { useRef } from 'react';
import useConfirm from './useConfirm/useConfirm';
import ModalForm from '../ModalForm';

const useFormModal = () => {
  const resolverRef = useRef(null);
  const submitRef = useRef(null);

  // Promise used to return data from useFormModal once the form data has been submitted, received, and accepted
  const { promise, resolve: resolveForm, reject: rejectForm } = Promise.withResolvers();

  const onSubmit = (input) => {
    if (resolverRef.current) {
      resolverRef.current();
    }
    resolveForm(input);
  };

  const confirmAction = async (resolver) => {
    // resolverRef resolves the promise from useConfirm which closes the modal
    resolverRef.current = resolver;
    submitRef.current.click();
  };

  const defaultProps = {
    title: '',
    onSubmit,
    submitRef,
    confirmAction,
    confirmationButtonProps: {
      variant: 'contained',
    },
  };

  const confirm = useConfirm();
  return (props) => {
    confirm({
      ...defaultProps,
      ...props,
      content: <ModalForm fields={props.fields} onSubmit={onSubmit} submitRef={submitRef} />,
    }).catch(() => {
      rejectForm();
    });
    return promise;
  };
};

export default useFormModal;
