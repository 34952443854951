import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import {Link} from '@mui/material';

import ResetPasswordForm from './ResetPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import {PageContext} from '../../../lib/contexts/pageContext';

const ResetPassword = () => {
  const { setPageMessage, setPageErrorMessage } = useContext(PageContext);

  const [resetState, setResetState] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = searchParams.get('user');
  const code = searchParams.get('code');

  useEffect(() => {
    if ((user && !code) || (!user && code)) {
      setPageErrorMessage('Invalid reset password URL. Please try the link again or resubmit the reset request.');
      setResetState('email');
    } else if (user && code) {
      setResetState('code');
    } else {
      setResetState('email');
    }
  }, [code, user, searchParams, setPageErrorMessage]);

  return (
    <>
      { resetState === 'email' && (
        <ResetPasswordForm
          setPageMessage={setPageMessage}
          setPageErrorMessage={setPageErrorMessage}
        />
      )}
      { resetState === 'code' && (
        <ChangePasswordForm
          setPageMessage={setPageMessage}
          setPageErrorMessage={setPageErrorMessage}
          user={user}
          code={code}
        />
      )}
      { resetState !== null && (
        <Grid container spacing={3}>
          <Grid xs={12} display="flex" justifyContent={'center'}>
            <Link
              component={'button'}
              variant="body1"
              color="secondary"
              underline="none"
              sx={{marginTop: '15px'}}
              onClick={() => {
                navigate('/login');
              }}
            >
              Back to Sign In
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ResetPassword;
